import { ChevronLeft, ChevronRight, Users } from "lucide-react";
import { useAuth } from "@/context/AuthContext";
import WorkspaceCard from "./WorkspaceCard";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  dispatch: React.Dispatch<any>;
  adminState: any;
  users: any;
  heading: string;
  role: string;
}
export default function SuperAdminView({
  dispatch,
  adminState,
  heading,
  role,
  users,
}: Props) {
  const { state, addReadOnlyUser } = useAuth();
  const { pathname } = useLocation();
  const data: any = adminState?.loadedSuggestion
    ? adminState?.suggestedUsers
    : users;
  const filteredData = data?.filter((user: any) => {
    return user.accountStatus === "ACTIVE";
  });
  const totalPages = Math.ceil(filteredData.length / adminState?.usersPerPage);
  const indexOfLastItem = adminState?.currentPage * adminState?.usersPerPage;
  const indexOfFirstItem = indexOfLastItem - adminState?.usersPerPage;
  const currentItems =
    filteredData.length > 40
      ? filteredData.slice(indexOfFirstItem, indexOfLastItem)
      : filteredData;
  const isLoading = adminState?.loading;
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  const handleNextPage = () => {
    if (adminState?.currentPage < totalPages) {
      dispatch({ type: "NEXT_PAGE" });
    }
  };

  const handlePrevPage = () => {
    if (adminState?.currentPage > 1) {
      dispatch({ type: "PREV_PAGE" });
    }
  };

  const handlePageClick = (pageNumber: number) => {
    dispatch({ type: "SET_PAGE", payload: pageNumber });
  };

  if (isLoading) {
    return (
      <div className="flex flex-col md:flex-row items-center justify-center absolute top-0 left-0 w-full h-full bg-white bg-opacity-50">
        <span className="authentication-loader scale-150"></span>
      </div>
    );
  }

  if (
    (!filteredData && filteredData?.length === 0) ||
    (adminState?.loadedSuggestion && adminState.suggestedUsers?.length === 0)
  ) {
    return (
      <div className="rounded-lg bg-white p-8 text-center">
        <Users className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-semibold text-gray-900">
          {`No ${role} Found`}
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          {`No ${role} accounts have been created yet.`}
        </p>
      </div>
    );
  }

  useEffect(() => {
    dispatch({ type: "SET_PAGE", payload: 1 });
  }, [pathname]);

  useEffect(() => {
   const wrapper = document.getElementById('items')
   wrapper?.scrollIntoView({
    behavior: 'auto'
   })
  }, [adminState?.currentPage]);

  return (
    <div id="items" className="scroll-mt-20">
      <h1 className="text-2xl font-semibold text-gray-900">{heading}</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-6 mt-8">
        {currentItems?.map((user: any) => {
          return (
            <WorkspaceCard
              phoneNumber={user?.phoneNumber}
              key={user?.id}
              id={user?.id}
              email={user?.email}
              title={user?.name}
              onViewClick={() => {
                addReadOnlyUser(user);
                dispatch({ type: "SET_LOADING", payload: true });
              }}
            />
          );
        })}
      </div>
      {users?.length > 54 && pageNumbers?.length > 1 && (
        <div className="flex justify-center space-x-2 items-center mt-10">
          <button
            onClick={handlePrevPage}
            disabled={adminState?.currentPage === 1}
            className=""
          >
            <ChevronLeft />
          </button>

          <div className="flex flex-wrap gap-2">
            {pageNumbers.map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => handlePageClick(pageNumber)}
                className={`w-5 h-5 text-xs rounded-full ${
                  adminState?.currentPage === pageNumber
                    ? "bg-primary text-white"
                    : "bg-gray-200"
                }`}
              >
                {pageNumber}
              </button>
            ))}
          </div>

          <button
            onClick={handleNextPage}
            disabled={adminState?.currentPage === totalPages}
            className=""
          >
            <ChevronRight />
          </button>
        </div>
      )}
    </div>
  );
}

import { useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "@/lib/firebase/app";
import { User } from "@/lib/types";
import { MessageCircleWarningIcon, Users } from "lucide-react";
import Button from "@/components/ui/Button";
import Modal from "./ui/Modal";
import { useAuth } from "@/context/AuthContext";
import ButtonLoader from "./ui/ButtonLoader";
import { generateApprovalEmailFunction } from "@/email template/generateApprovalEmail";
import { Select } from "antd";
import { deleteSuperAdmin } from "@/api/user";
import { CustomToast } from "@/utils/helpers";
import { useAdmin } from "@/context/AdminContext";


interface SuperAdminTableProps {
  id?: string;
}

export type Action =
  | "DISABLED_ACCESS"
  | "ENABLED_ACCESS"
  | "DELETE_PERMANENTLY"
  | null;

const INITIAL_STATE = {
  isUpdating: false,
  actionType: null,
  selectedSuperAdmin: null,
  isModalOpen: false,
  modalName: null,
  error: null,
  formData: {
    action: null,
  },
};
export default function SuperAdminTable({
  id,
}: SuperAdminTableProps) {
  const { getToken } = useAuth();
  const { state, dispatch} = useAdmin();
  console.log({ state });

  const [modalState, setModalState] = useState<{
    isUpdating: boolean;
    actionType: "approve" | "disapprove" | "delete" | null;
    selectedSuperAdmin: User | null;
    isModalOpen: boolean;
    modalName: "Approve" | "Delete" | null;
    error: string | null;
    formData: {
      action: Action;
    };
  }>({
    ...INITIAL_STATE,
  });


  const sortedSuperAdmins = state?.loadedSuggestion
    ? state?.suggestedUsers
    : state?.allSuperAdmins?.sort((a, b) => {
        if (a.accountStatus === "PENDING" && b.accountStatus !== "PENDING")
          return -1;
        if (a.accountStatus !== "PENDING" && b.accountStatus === "PENDING")
          return 1;
        return 0;
      });

  if (sortedSuperAdmins?.length === 0) {
    return (
      <div className="rounded-lg bg-white p-8 text-center">
        <Users className="mx-auto h-12 w-12 text-gray-400" />
        <h3 className="mt-2 text-sm font-semibold text-gray-900">
          No Super Admins
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          No Super Admin accounts have been created yet.
        </p>
      </div>
    );
  }

  const handleSuperAdminStatus = async (
    superAdminId: string,
    status: "ACTIVE" | "PENDING"
  ) => {
    try {
      setModalState((prev) => ({ ...prev, isUpdating: true }));
      const userRef = doc(db, "users", superAdminId);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        const user = docSnap.data() as User;
        const updatedUser = {
          ...user,
          accountStatus: status,
        };
        await updateDoc(userRef, updatedUser);
        dispatch({ type: "UPDATE_SUPER_ADMIN", payload: updatedUser });
      } else {
        console.log("No such document!");
      }

      if (status === "ACTIVE") {
        await generateApprovalEmailFunction({
          name: modalState?.selectedSuperAdmin?.name || "",
          email: modalState?.selectedSuperAdmin?.email || "",
        });
      }
      CustomToast(
        "success",
        `Requested Super Admin has ${
          status === "ACTIVE" ? "approved" : "disapproved"
        } successfully!`
      );

      setModalState(INITIAL_STATE);
    } catch (error: any) {
      console.log({ error: error.response.data.message });
      console.error(
        `Error occurred while ${
          status === "ACTIVE" ? "approving" : "unapproving"
        } super admin`,
        error
      );
      setModalState({
        ...modalState,
        error: error.response.data.message as string,
      });
    }
  };

  const handleDeleteSuperAdmin = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    try {
      const token = (await getToken()) as string;
      setModalState((prev) => ({ ...prev, isUpdating: true }));
      const response = await deleteSuperAdmin({
        action: modalState?.formData?.action,
        token,
        user: modalState?.selectedSuperAdmin as User,
      });
      let updatedUser;
      if (response?.success) {
        if (modalState.formData?.action === "DELETE_PERMANENTLY") {
          dispatch({
            type: "DELETE_SUPER_ADMIN",
            payload: modalState?.selectedSuperAdmin as User,
          });
        } else if (modalState.formData?.action === "DISABLED_ACCESS") {
          updatedUser = {
            ...modalState?.selectedSuperAdmin,
            disabled: true,
          };
          dispatch({
            type: "UPDATE_SUPER_ADMIN",
            payload: updatedUser as User,
          });
        } else {
          updatedUser = {
            ...modalState?.selectedSuperAdmin,
            disabled: false,
          };
          dispatch({
            type: "UPDATE_SUPER_ADMIN",
            payload: updatedUser as User,
          });
        }
      }
      console.log({ response });
      CustomToast("success", response?.message);
      setModalState(INITIAL_STATE);
    } catch (error: any) {
      setModalState({
        ...modalState,
        error: error.message as string,
        isUpdating: false,
      });
    }
  };
  console.log(modalState);
  console.log({state});
  

  return (
    <div className="mt-8 flow-root">
      <div className="-my-2 overflow-x-auto">
        <div className="inline-block min-w-full py-2 align-middle">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-3.5 w-1/5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Name
                  </th>
                  <th className="px-3 w-1/5 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Email
                  </th>
                  <th className="px-3 w-1/5 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Phone Number
                  </th>
                  <th className="px-3 w-[15%]  py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th className="px-3 w-1/5 py-3.5 text-center text-sm font-semibold text-gray-900">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {sortedSuperAdmins?.map((superAdmin) => (
                  <tr
                    id={superAdmin?.id}
                    key={superAdmin.id}
                    className={id === superAdmin.id ? "!bg-gray-200/70" : ""}
                  >
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {superAdmin.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {superAdmin.email}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {superAdmin.phoneNumber}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {superAdmin.accountStatus}
                    </td>
                    <td className="flex items-center justify-center gap-4 whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <Button
                        onClick={() => {
                          setModalState({
                            ...modalState,
                            actionType:
                              superAdmin.accountStatus === "ACTIVE"
                                ? "disapprove"
                                : "approve",
                            selectedSuperAdmin: superAdmin,
                            modalName: "Approve",
                            isModalOpen: true,
                          });
                        }}
                        className={
                          superAdmin.accountStatus === "PENDING"
                            ? "bg-blue-600"
                            : "bg-black/60"
                        }
                        disabled={modalState?.isUpdating}
                        aria-busy={modalState?.isUpdating}
                      >
                        {superAdmin.accountStatus === "ACTIVE"
                          ? "Disapprove"
                          : "Approve"}
                      </Button>
                      <Button
                        onClick={() => {
                          setModalState({
                            ...modalState,
                            actionType: "delete",
                            selectedSuperAdmin: superAdmin,
                            modalName: "Delete",
                            isModalOpen: true,
                          });
                        }}
                        variant="danger-outline"
                      >
                        {superAdmin?.disabled ? "Enable" : "Delete"}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {modalState?.isModalOpen && (
        <Modal
          isOpen={modalState?.isModalOpen}
          onClose={() => {
            setModalState(INITIAL_STATE);
          }}
          title={`${
            modalState?.actionType === "approve"
              ? "Approve"
              : modalState?.actionType === "delete"
              ? modalState?.selectedSuperAdmin?.disabled
                ? "Enable Access"
                : "Delete"
              : "Disapprove"
          } Super Admin`}
        >
          {modalState?.modalName === "Approve" && (
            <>
              <p>
                Are you sure you want to{" "}
                <span className="font-semibold">
                  {modalState?.actionType === "approve"
                    ? "approve"
                    : "disapprove"}
                </span>{" "}
                this user?
              </p>
              <div className="flex items-center justify-end gap-3 mt-4">
                <Button
                  disabled={modalState?.isUpdating}
                  onClick={() => setModalState(INITIAL_STATE)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={modalState?.isUpdating}
                  aria-busy={modalState?.isUpdating}
                  onClick={() => {
                    handleSuperAdminStatus(
                      modalState?.selectedSuperAdmin?.id as string,
                      modalState?.actionType === "approve"
                        ? "ACTIVE"
                        : "PENDING"
                    );
                  }}
                >
                  Confirm
                  {modalState?.isUpdating && <ButtonLoader />}
                </Button>
              </div>
            </>
          )}

          {modalState?.modalName === "Delete" && (
            <>
              <form onSubmit={handleDeleteSuperAdmin} className="space-y-4">
                {modalState?.error && (
                  <div className="rounded-md bg-red-50 p-4">
                    <p className="text-sm text-red-700">{modalState?.error}</p>
                  </div>
                )}
                <div>
                  <label
                    htmlFor="action"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Action
                  </label>
                  <Select
                    id="action"
                    defaultValue={modalState?.formData?.action || null}
                    value={modalState?.formData?.action || null}
                    onChange={(value) => {
                      setModalState((prev) => ({
                        ...prev,
                        formData: {
                          ...prev.formData,
                          action: value as
                            | "DISABLED_ACCESS"
                            | "DELETE_PERMANENTLY"
                            | "ENABLED_ACCESS",
                        },
                      }));
                    }}
                    className="w-full mt-1"
                    placeholder="Please select an action"
                    options={[
                      !modalState?.selectedSuperAdmin?.disabled
                        ? {
                            label: "Disable Access",
                            value: "DISABLED_ACCESS",
                          }
                        : {
                            label: "Enable Access",
                            value: "ENABLED_ACCESS",
                          },

                      {
                        label: "Delete Permanently",
                        value: "DELETE_PERMANENTLY",
                      },
                    ]}
                  />
                </div>

                <div className="min-h-8 !mt-2">
                  {modalState?.formData?.action === "DELETE_PERMANENTLY" && (
                    <span className="text-xs text-gray-500 flex items-center gap-1">
                      <MessageCircleWarningIcon
                        height={15}
                        width={15}
                        className="w-4 h-4 min-w-4 md:w-auto md:h-auto"
                      />{" "}
                      This action will permanently remove the super admin and
                      cannot be reversed. Please proceed with caution.
                    </span>
                  )}
                  {modalState?.formData?.action === "DISABLED_ACCESS" && (
                    <span className="text-xs text-gray-500 flex items-center gap-1">
                      <MessageCircleWarningIcon
                        height={15}
                        width={15}
                        className="w-4 h-4 min-w-4 md:w-auto md:h-auto"
                      />{" "}
                      Disabling this account will restrict access for all
                      assigned admins and agents, making it unavailable.
                      Re-enable the account to restore its functionality.
                    </span>
                  )}

                  {modalState?.formData?.action === "ENABLED_ACCESS" && (
                    <span className="text-xs text-gray-500 flex items-center gap-1">
                      <MessageCircleWarningIcon
                        height={15}
                        width={15}
                        className="w-4 h-4 min-w-4 md:w-auto md:h-auto"
                      />{" "}
                      Enabling this account will restore access for all
                      assigned admins and agents, making it available.
                    </span>
                  )}
                </div>
                <div className="mt-5 sm:mt-4 flex flex-row gap-2 items-center justify-end">
                <Button
                    disabled={modalState?.isUpdating}
                    type="button"
                    variant="outline"
                    onClick={() => {
                      setModalState(INITIAL_STATE);
                    }}
                    className="w-auto"
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      !modalState?.formData?.action || modalState?.isUpdating
                    }
                    type="submit"
                    className="w-auto"
                  >
                    Submit
                    {modalState?.isUpdating && <ButtonLoader />}
                  
                  </Button>
                 
                </div>
              </form>
            </>
          )}
        </Modal>
      )}
    </div>
  );
}

import { generateId } from "@/lib/utils";
import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
} from "react";

export interface Data {
  name: string;
  email: string;
  officeIds?: string[];
  categoryIds?: string[];
  role: string;
  id?: string;
  phoneNumber:string;
}

export interface Report {
  email: string;
  success: boolean;
  message?: string;
}

interface BulkUploadState {
  data: Data[];
  fileName: string;
  loading: boolean;
  error: string | null;
  step: number;
  report: Report[];
  showReport: boolean;
  currentPage: number;
  usersPerPage: number;
}

type BulkUploadAction =
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_STEP"; payload: number }
  | { type: "SET_DATA"; payload: { data: Data[]; fileName: string } }
  | { type: "SET_ERROR"; payload: string | null }
  | { type: "SET_REPORT"; payload: { report: Report[]; showReport: boolean } }
  | { type: "DELETE_ROW"; payload: string }
  | { type: "UNSET_REPORT" }
  | { type: "CLEAR" }
  | { type: "UPDATE_ROW"; payload: { id: string; data: Partial<Data> } }
  | { type: "ADD_ROW"} 
  | {  type: "SET_PAGE" ; payload: number }
  | { type: "NEXT_PAGE" }
  | { type: "PREV_PAGE" };;

const initialState: BulkUploadState = {
  data: [],
  loading: false,
  fileName: "",
  error: null,
  step: 1,
  showReport: false,
  report: [],
  currentPage: 1,
  usersPerPage: 30,
};

interface BulkUploadContextType {
  state: BulkUploadState;
  dispatch: React.Dispatch<BulkUploadAction>;
}

const BulkUploadContext = createContext<BulkUploadContextType | null>(null);

function adminReducer(
  state: BulkUploadState,
  action: BulkUploadAction
): BulkUploadState {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload, error: null };
    case "SET_STEP":
      return { ...state, step: action.payload, error: null };
    case "SET_DATA":
      return {
        ...state,
        data: [...action.payload.data],
        fileName: action?.payload?.fileName,
        error: ((!action?.payload?.data?.length || !action?.payload?.fileName) && 'Missing required dependencies') as string,
      };
    case "CLEAR":
      return {
        ...state,
        data: [],
        fileName: "",
        error: null,
      };
    case "UNSET_REPORT":
      return {
        ...state,
        report: [],
        showReport: false,
        fileName: "",
        data: [],
        error: null,
        step: 1,
      };

    case "SET_REPORT":
      return {
        ...state,
        report: action.payload.report,
        showReport: action.payload.showReport,
        error: null,
      };
    case "SET_ERROR":
      return { ...state, error: action.payload, loading: false };
    case "UPDATE_ROW": {
      const updatedData = state.data.map((item) =>
        item.id === action.payload.id
          ? { ...item, ...action.payload.data }
          : item
      );
      return { ...state, data: updatedData };
    }
    case "DELETE_ROW": {
      const updatedData = state.data.filter(
        (item) => item.id !== action.payload
      );
      return { ...state, data: updatedData };
    }
    case "ADD_ROW": {
      const updatedData = [
        ...state.data,
        {
          name: "",
          email: "",
          officeIds: [],
          categoryIds: [],
          role: "",
          id: generateId(),
          phoneNumber: "",
        },
      ];
      return { ...state, data: updatedData };
    }
    case "NEXT_PAGE":
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };
    case "PREV_PAGE":
      return {
        ...state,
        currentPage: state.currentPage > 1 ? state.currentPage - 1 : 1,
      };
      case "SET_PAGE": // New Case for Pagination
      return {
        ...state,
        currentPage: action.payload,
      };
    default:
      return state;
  }
}

export function BulkUploadProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(adminReducer, initialState);

  return (
    <BulkUploadContext.Provider value={{ state, dispatch }}>
      {children}
    </BulkUploadContext.Provider>
  );
}

export function useBulkUpload() {
  const context = useContext(BulkUploadContext);
  if (!context) {
    throw new Error("useBulkUpload must be used within a BulkUploadProvider");
  }
  return context;
}

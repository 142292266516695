import { useAdmin } from "@/context/AdminContext";
import { Data, useBulkUpload } from "@/context/BulkUploadContext";
import { Select } from "antd";
import PhoneNumberInput from "../PhoneInput";
import Button from "../ui/Button";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";

export const options = [
  {
    label: "Agent",
    value: "AGENT",
  },
  {
    label: "Admin",
    value: "ADMIN",
  },
  {
    label: "Vendor",
    value: "VENDOR",
  },
];
export default function BulkUploadStep2() {
  const { state: adminState } = useAdmin();
  const { dispatch, state } = useBulkUpload();
  const totalPages = Math.ceil(state?.data.length / state?.usersPerPage);
  const indexOfLastItem = state?.currentPage * state?.usersPerPage;
  const indexOfFirstItem = indexOfLastItem - state?.usersPerPage;
  const currentItems =
    state?.data.length > 40
      ? state?.data.slice(indexOfFirstItem, indexOfLastItem)
      : state?.data;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, []);

  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  // Handlers
  const handleNextPage = () => {
    if (state?.currentPage < totalPages) {
      dispatch({ type: "NEXT_PAGE" });
    }
  };

  const handlePrevPage = () => {
    if (state?.currentPage > 1) {
      dispatch({ type: "PREV_PAGE" });
    }
  };

  const handlePageClick = (pageNumber: number) => {
    dispatch({ type: "SET_PAGE", payload: pageNumber });
  };

  // console.log({ state });
  const handleInputChange = (id: string, field: string, value: any) => {
    dispatch({
      type: "UPDATE_ROW",
      payload: {
        id,
        data: { [field]: value },
      },
    });
  };

  const handleSelectInputChange = (
    id: string,
    field: string,
    value: any,
    node?: Data
  ) => {
    let updatedValue = value;

    // Ensure officeIds is always an array
    if (field === "officeIds") {
      if (typeof value === "string") {
        updatedValue = [value]; // Convert a single value to an array
      }
    }

    // Handle role field separately for AGENT to reset categories
    if (field === "role") {
      const categoriesIds = value === "AGENT" ? [] : null;
      const officeId =
        node?.officeIds && node?.officeIds?.length > 1 && node?.officeIds[0];
      const officesIds = value === "AGENT" ? officeId : null;

      dispatch({
        type: "UPDATE_ROW",
        payload: {
          id,
          data: {
            [field]: value,
            categoryIds: categoriesIds ? categoriesIds : node?.categoryIds,
            officeIds: officesIds
              ? ([officesIds] as string[])
              : (node?.officeIds as string[]),
          },
        },
      });
    } else {
      dispatch({
        type: "UPDATE_ROW",
        payload: {
          id,
          data: {
            [field]: updatedValue,
          },
        },
      });
    }
  };

  const handleDeleteRow = (id: string) => {
    if (state?.data?.length === 1) {
      dispatch({
        type: "SET_STEP",
        payload: 1,
      });
      dispatch({
        type: "UNSET_REPORT",
      });
      dispatch({
        type: "DELETE_ROW",
        payload: id,
      });
    }
    dispatch({
      type: "DELETE_ROW",
      payload: id,
    });
  };

  const checkMail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    console.log(emailRegex.test(email));

    return !emailRegex.test(email);
  };

  const addRow = async () => {
    dispatch({
      type: "ADD_ROW",
    });
  };

  return (
    <div>
      <div className="mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">
          Previewing User Details
        </h1>
        <p className="ml-1 text-sm text-gray-500">
          Assignment of Offices and Categories
        </p>
      </div>
      {loading ? (
        <div className="flex w-full items-center justify-center h-[calc(100vh-400px)] shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg" >
          <span
            className="authentication-loader
authentication-loader scale-150"
          ></span>
        </div>
      ) : (
        <div>
          <div className="inline-block  align-middle min-w-[1140px] lg:min-w-[1280px] w-full">
            <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <div className="grid grid-cols-12 min-w-full border-b border-gray-300 bg-gray-50">
                <div className="col-span-2 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Name
                </div>
                <div className="col-span-2 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Email
                </div>
                <div className="col-span-2 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Phone Number
                </div>
                <div className="col-span-2 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Role
                </div>
                <div className="col-span-2 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Offices
                </div>
                <div className="col-span-2 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  Categories
                </div>
              </div>
              {state?.data &&
                currentItems?.map((node: Data) => {
                  return (
                    <div
                      key={node?.id}
                      className="grid grid-cols-12 py-4 min-w-full border-b border-gray-200 bg-white items-center"
                    >
                      <div className="col-span-2 flex gap-3 items-center justify-center px-3 text-sm font-medium text-gray-900">
                        {state?.data?.length > 1 && (
                          <button
                            onClick={() => handleDeleteRow(node?.id as string)}
                            className="inline-flex items-center justify-center text-xs rounded-full font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-blue-600 text-white hover:bg-blue-700 focus-visible:ring-blue-600 min-w-5 w-5 h-5"
                          >
                            X
                          </button>
                        )}
                        <input
                          type="text"
                          id="name"
                          value={node?.name}
                          onChange={(e) =>
                            handleInputChange(
                              node?.id as string,
                              "name",
                              e.target.value
                            )
                          }
                          placeholder="Enter the Name"
                          className=" px-1 py-[5px] lock w-full rounded-md border-border-default shadow-sm focus:border-blue-antd focus-visible:border-blue-antd focus-visible:outline-0   border focus:ring-blue-antd sm:text-sm disabled:cursor-not-allowed"
                          required
                        />
                      </div>
                      <div className="col-span-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <input
                          type="email"
                          id="email"
                          value={node?.email}
                          onChange={(e) =>
                            handleInputChange(
                              node?.id as string,
                              "email",
                              e.target.value
                            )
                          }
                          placeholder="Enter the Email"
                          className={` ${
                            node?.email && checkMail(node?.email?.trim())
                              ? "border-red-500 focus:border-red-700 focus-visible:border-red-700 focus:ring-red-500"
                              : "border-border-default focus:border-blue-antd focus-visible:border-blue-antd focus:ring-blue-antd"
                          } px-1 py-[5px] lock w-full rounded-md  shadow-sm  focus-visible:outline-0   border  sm:text-sm disabled:cursor-not-allowed`}
                          required
                        />
                        {node?.email && checkMail(node?.email?.trim()) && (
                          <p className="text-red-500 font-normal text-sm">
                            Enter a valid email address.{" "}
                          </p>
                        )}
                      </div>
                      <div
                        id="phoneNuberCell"
                        className="col-span-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                      >
                        <PhoneNumberInput
                          required={true}
                          name="phoneNumber"
                          value={node?.phoneNumber}
                          handleChange={(phone) =>
                            handleInputChange(
                              node?.id as string,
                              "phoneNumber",
                              phone
                            )
                          }
                          variant="secondary"
                        />
                      </div>
                      <div className="col-span-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <Select
                          className="w-full"
                          placeholder="Please select role"
                          value={
                            options?.find((opt) => opt.value === node?.role) &&
                            node?.role
                          }
                          defaultValue={
                            options?.find((opt) => opt.value === node?.role) &&
                            node?.role
                          }
                          onChange={(value) =>
                            handleSelectInputChange(
                              node?.id as string,
                              "role",
                              value,
                              node
                            )
                          }
                          options={options}
                        />
                      </div>
                      <div className="col-span-2  pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <Select
                          mode={(node?.role === "ADMIN" || node?.role === "VENDOR") ? "multiple" : undefined}
                          allowClear
                          className="w-full"
                          placeholder="Please select offices"
                          onChange={(value) =>
                            handleSelectInputChange(
                              node?.id as string,
                              "officeIds",
                              value,
                              node
                            )
                          }
                          value={node?.officeIds || []}
                          defaultValue={node?.officeIds || []}
                          options={adminState?.offices?.map((office: any) => {
                            return {
                              label: office?.name,
                              value: office?.id,
                            };
                          })}
                        />
                      </div>
                      <div className="col-span-2  pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {node?.role === "ADMIN" && (
                          <Select
                            mode="multiple"
                            allowClear
                            style={{ width: "100%" }}
                            placeholder="Please select categories"
                            defaultValue={node?.categoryIds || []}
                            value={node?.categoryIds || []}
                            onChange={(value) =>
                              handleSelectInputChange(
                                node?.id as string,
                                "categoryIds",
                                value,
                                node
                              )
                            }
                            options={adminState?.categories
                              .map((office: any) => {
                                return {
                                  label: office?.name,
                                  value: office?.id,
                                };
                              })}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              <div className="w-full flex justify-end items-center gap-4 bg-white py-2 px-2">
                <div className="flex justify-center space-x-2 items-center">
                  <button
                    onClick={handlePrevPage}
                    disabled={state?.currentPage === 1}
                    className=""
                  >
                    <ChevronLeft />
                  </button>

                  <div className="flex space-x-2">
                    {pageNumbers.map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => handlePageClick(pageNumber)}
                        className={`w-5 h-5 text-xs rounded-full ${
                          state?.currentPage === pageNumber
                            ? "bg-primary text-white"
                            : "bg-gray-200"
                        }`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>

                  <button
                    onClick={handleNextPage}
                    disabled={state?.currentPage === totalPages}
                    className=""
                  >
                    <ChevronRight />
                  </button>
                </div>
                <Button onClick={() => addRow()} className="">
                  Add Row
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

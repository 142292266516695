import { useAdmin } from "@/context/AdminContext";
import { Data, useBulkUpload } from "@/context/BulkUploadContext";
import { Select } from "antd";
import clsx from "clsx";

export default function BulkUploadStep3() {
  const { state: adminState } = useAdmin();
  const { dispatch, state } = useBulkUpload();

  console.log({ state });
  return (
    <>
      <div>
        <div className="mb-6">
          <h1 className="text-2xl font-semibold text-gray-900">
            Previewing Details
          </h1>
        </div>
        <div className={clsx("overflow-x-auto")}>
          <div className="inline-block  align-middle min-w-[1024px] lg:min-w-[1280px] w-full">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <div className="grid grid-cols-12 min-w-[1024px] lg:min-w-[1280px] border-b border-gray-300 bg-gray-50 items-center gap-3">
                <div className="col-span-2 py-3.5 text-left text-sm font-semibold text-gray-900 pl-6">
                  Name
                </div>
                <div className="col-span-2 py-3.5  text-left text-sm font-semibold text-gray-900 ">
                  Email
                </div>
                <div className="col-span-2 py-3.5  text-left text-sm font-semibold text-gray-900 ">
                  Phone Number
                </div>
                <div className="col-span-2 py-3.5  text-left text-sm font-semibold text-gray-900 ">
                  Role
                </div>
                <div className="col-span-2 py-3.5  text-left text-sm font-semibold text-gray-900 ">
                  Offices
                </div>
                <div className="col-span-2 py-3.5  text-left text-sm font-semibold text-gray-900 ">
                  Categories
                </div>
              </div>
              {state?.data &&
                state?.data?.map((node: Data) => {
                  return (
                    <div
                      key={node?.id}
                      className="grid grid-cols-12 w-[1024px] lg:min-w-[1280px] border-b border-gray-200 bg-white items-center gap-3"
                    >
                      <div className="pl-6 col-span-2 whitespace-normal py-4  text-sm font-medium text-gray-900 ">
                        {node?.name}
                      </div>
                      <div className="col-span-2 truncate py-4  text-sm font-medium text-gray-900 ">
                        {node?.email}
                      </div>
                      <div className="col-span-2 whitespace-normal py-4  text-sm font-medium text-gray-900 ">
                        {node?.phoneNumber}
                      </div>
                      <div className="col-span-2 whitespace-normal py-4  text-sm font-medium text-gray-900 ">
                        {node?.role}
                      </div>
                      <div className="col-span-2 whitespace-normal py-4  text-sm font-medium text-gray-900 ">
                        {node?.officeIds
                          ?.map((node) => {
                            const name = adminState?.offices?.find(
                              (office: any) => node === office?.id
                            )?.name;
                            return name;
                          })
                          .join(", ")}
                      </div>
                      <div className="col-span-2 whitespace-normal py-4  text-sm font-medium text-gray-900 pr-4">
                        {(node?.role === "ADMIN" || node?.role === "VENDOR") &&
                          node?.categoryIds
                            ?.map((node) => {
                              const name = adminState?.categories?.find(
                                (office: any) => node === office?.id
                              )?.name;
                              return name;
                            })
                            .join(", ")}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {state?.loading && (
        <div className=" h-full w-full flex justify-center items-center absolute top-0 left-0 bg-white bg-opacity-20">
          <span
            className="authentication-loader
          authentication-loader scale-150"
          ></span>
        </div>
      )}
    </>
  );
}

import { useEffect } from "react";
import SuperAdminTable from "@/components/SuperAdminsTable";
import { useLocation } from "react-router-dom";
import { useAdmin } from "@/context/AdminContext";

const SuperAdmins = () => {
  const {state} = useAdmin();
  const location = useLocation();

  
  useEffect(() => {
    const scrollToId = location?.state?.scrollToId;
    if (scrollToId) {
      const elem = document.getElementById(scrollToId);
      if (elem) {
        setTimeout(() => {
          elem.scrollIntoView({
            behavior: "smooth",
          });
        }, 100);
      }
    }
  }, [location.state?.scrollToId]); 
  
  return <SuperAdminTable id={location.state?.scrollToId} />;
};

export default SuperAdmins;

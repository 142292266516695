// Simulated AI response for development
import { collection, getDocs, query, where } from "firebase/firestore";
import { getOpenAiClient } from "./openai/client";
import { db } from "./firebase";

export async function generateAIResponse(transcript: string): Promise<string> {
  return `Thank you for your request. I understand you're asking about: "${transcript}". 
  As this is a development environment, I'm providing a simulated response. In production, 
  this would connect to the OpenAI API for real responses.`;
}

export async function analyzeTranscript(
  transcript: string,
  categories: any,
  apiKey?: string
): Promise<{
  title: string;
  category: string;
  aiResponse: string;
}> {
  const { title, category, aiResponse } = await analyzeText(
    transcript,
    categories,
    apiKey
  );
  return {
    title: title,
    category: category,
    aiResponse: aiResponse,
  };
}

const analyzeText = async (
  text: string,
  categories: any,
  apiKey?: string
): Promise<{ category: string; aiResponse: string; title: string }> => {
  const categoryNames = categories
    .map((category: any) => category.name)
    .join(", ");

  const prompt = `
Please do the following for the given text:

1. **Title**: Provide a short title summarizing the key points of the text.
2. **Response**: Provide the AI's response to the query within the text, based on the content provided.
3. **Category**: Classify the text into one of the following categories: ${categoryNames}

Text: "${text}"

Please format your response like this:
Title: [title]
Response: [reponse]
Category: [category]
`;

  try {
    const openai = getOpenAiClient({ apiKey });
    const response = await openai.chat.completions.create({
      messages: [
        {
          role: "system",
          content:
            "You are an AI that generates a title, reponse, and category for text.",
        },
        { role: "user", content: prompt },
      ],
      model: "gpt-4",
      max_tokens: 200,
    });

    if (response?.choices[0]?.message?.content) {
      const result = response.choices[0].message.content.trim();

      const sections = result.split(/(?=(Title:|Response:|Category:))/g);

      let title = "No title provided";
      let aiResponse = "No Ai response provided";
      let category = "Uncategorized";

      sections.forEach((section: any) => {
        if (section.startsWith("Title:")) {
          title = section.replace("Title:", "").trim();
        } else if (section.startsWith("Response:")) {
          aiResponse = section.replace("Response:", "").trim();
        } else if (section.startsWith("Category:")) {
          category = section.replace("Category:", "").trim();
        }
      });

      return {
        title,
        aiResponse,
        category,
      };
    }
    return {
      title: "No title provided",
      aiResponse: "No Ai response provided",
      category: "Uncategorized",
    };
  } catch (error) {
    console.error("Error classifying text:", error);
    throw new Error("Error while calling OpenAI for text classification");
  }
};

export const getAssistantResponse = async ({
  message,
  openaiAssistantid,
  apiKey,
}: {
  message: string;
  openaiAssistantid: string;
  apiKey?: string;
}): Promise<string | undefined> => {
  try {
    const openai = getOpenAiClient({ apiKey });

    // Step 1: Create a new thread
    const thread = await openai.beta.threads.create();

    const userMessage = await openai.beta.threads.messages.create(
      thread.id,
      "user",
      message
    );

    // Step 3: Create a run with the assistant
    const assistantId = openaiAssistantid;
    const run = await openai.beta.threads.runs.create(thread.id, assistantId);

    // Step 4: Check the status of the run
    if (run.status === "completed") {
      const messages = await openai.beta.threads.messages.list(run.thread_id);
      const res = messages?.data?.filter(
        (message: any) => message.role === "assistant"
      );
      return res[0].content[0].text?.value;
    } else {
      console.log("Run status:", run.status);
    }
  } catch (error) {
    console.error("Error during assistant interaction:", error);
  }
};

export const sendMessageToAssistant = async (
  threadId: string,
  input: string,
  assistantId: string,
  apiKey?: string
): Promise<string> => {
  try {
    const openai = getOpenAiClient({ apiKey });
    await openai.beta.threads.messages.create(threadId, "user", input);

    const run = await openai.beta.threads.runs.create(threadId, assistantId);

    let runStatus = await openai.beta.threads.runs.retrieve(threadId, run.id);
    while (runStatus.status !== "completed") {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      runStatus = await openai.beta.threads.runs.retrieve(threadId, run.id);
    }

    const messages = await openai.beta.threads.messages.list(threadId);
    const assistantMessage = messages.data.find(
      (msg: any) => msg.role === "assistant"
    );

    if (assistantMessage) {
      return assistantMessage.content[0].text.value;
    } else {
      throw new Error("No response from the assistant.");
    }
  } catch (error) {
    console.error("Error sending message to assistant:", error);
    throw error;
  }
};

export const fetchThreadMessages = async (threadId: string, apiKey?: string): Promise<{ role: string; content: string }[]> => {
  try {
    const openai = getOpenAiClient({ apiKey });
    const response = await openai.beta.threads.messages.list(threadId);
    if (response?.data) {
      return response.data.map((msg: any) => ({
        role: msg.role,
        content: msg.content[0]?.text?.value || "",
      })).reverse(); // Reverse to show the latest message at the bottom
    }
    return [];
  } catch (error) {
    console.error("Error fetching messages:", error);
    throw error; // Re-throw the error for handling in the calling function
  }
};


export const fetchUserThreads = async (
  userId: string,
  promptId: string
): Promise<{ threadId: string; createdAt: string; title: string ; promptId:string}[]> => {
  try {
    const threadsRef = collection(db, `users/${userId}/threads`);

    const q = query(threadsRef, where("promptId", "==", promptId));

    const querySnapshot = await getDocs(q);

    const threads = querySnapshot.docs.map((doc) => ({
      threadId: doc.id,
      createdAt: doc.data().createdAt,
      title: doc.data().title || "New Chat",
      promptId: doc.data().promptId,
    }));

    return threads;
  } catch (error) {
    console.error("Error fetching user threads:", error);
    throw error;
  }
};

import { useAuth } from "@/context/AuthContext";
import { APP_VERSION } from "@/lib/version";
import { Link } from "react-router-dom";

export default function Footer({otherClasses}:{otherClasses?:string}) {
    const {state: { user },} = useAuth();
  return (
    <footer className={`${otherClasses} bg-gray-900 text-gray-400 text-sm pt-4 pb-6 sm:py-2 z-[10] fixed bottom-0 right-0 w-full lg:px-4  lg:left-auto lg:right-0 ${user?.accountStatus === "ACTIVE" ? 'pl-16 pr-4' : ''}`}>
      <div className="flex flex-col gap-2 sm:flex-row justify-between sm:items-center">
        <span className="text-center">© {new Date().getFullYear()} Victoria by Market Impact Media, LLC. <br className="sm:hidden" /> All Rights Reserved </span>
        <div className="flex justify-center items-center gap-3">
          <Link to='/privacy-policy' className="mr-4 ">Privacy Policy</Link>
          <span>v{APP_VERSION}</span>
        </div>
      </div>
    </footer>
  );
}

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";
import { useAdmin } from "@/context/AdminContext";
import Button from "@/components/ui/Button";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/lib/firebase";
import { InfoIcon } from "lucide-react";
import Modal from "./ui/Modal";
import { isOwner } from "./OwnerRequired";
import { User } from "@/lib/types";

const TwillioConfiguration = () => {
  const navigate = useNavigate();
  const { state: authState, dispatch: authDispatch, updateUser } = useAuth();
  const user =
    authState?.readOnlyUser !== null ? authState.readOnlyUser : authState.user;

  const [enableTextFeature, setEnableTextFeature] = useState<boolean>(false);
  const [change, setChange] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!user) {
    navigate("/login");
    return null;
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setIsSubmitting(true);

    try {
      const currentUpdates: any = {
        ...user,
        enableTextFeature,
      };
      await updateUser(currentUpdates);
      authDispatch({ type: "UPDATE_USER", payload: currentUpdates });
    } catch (err: any) {
      console.log(err);
      setError(err.message || "Failed to update user data.");
    } finally {
      setIsSubmitting(false);
      setIsModalOpen(false);
    setChange(false);
    }
  };

  useEffect(() => {
    if (change) {
      setIsModalOpen(true);
    }
  }, [change]);

  const onClose = () => {
    setIsModalOpen(false);
    setChange(false);
    const {enableTextFeature = false} = user as User
    setEnableTextFeature(enableTextFeature);
  };


  useEffect(()=>{
    if(authState?.user){
      const { enableTextFeature=false } = user as User
          setEnableTextFeature(enableTextFeature);
    }

  },[user])
console.log({user});


  return (
    <>
      <div className=" border-b-[1px] pb-6 border-gray-200">
        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <p className="text-sm text-red-700">{error}</p>
          </div>
        )}

        {success && (
          <div className="rounded-md bg-green-50 p-4">
            <p className="text-sm text-green-700">{success}</p>
          </div>
        )}

        <div>
          <div className="flex flex-col xs:flex-row xs:justify-between xs:items-center">
            <h2 className="text-lg font-medium text-gray-900 mb-5 mt-3 flex gap-3 items-center">
              Enable Messaging Service
              <div className="group relative z-10">
                <InfoIcon className="cursor-pointer" height={17} width={17} />
                <p className="absolute rounded-md top-[25px] -right-8 xs:-right-24 hidden group-hover:block min-w-[250px] xs:min-w-[300px] bg-white shadow-2xl p-3 overflow-x-clip text-sm text-gray-700 font-medium">
                  Enable this toggle to activate the Twilio text feature. When
                  enabled, the system will use Twilio to send text messages.
                </p>
              </div>
            </h2>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={enableTextFeature}
                className="sr-only peer"
                onChange={() => {
                  setEnableTextFeature(!enableTextFeature);
                  setChange(true);
                }}
              />
              <div className="relative w-11 h-6 bg-gray-900  rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600 dark:peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={onClose} title={""}>
        <p>
          Are you sure you want to {enableTextFeature ? "enable" : "disable"}{" "}
          Twilio text feature?
        </p>
        <div className="flex items-center justify-end gap-3 mt-4">
          <Button onClick={onClose}>Cancel</Button>
          <Button disabled={isSubmitting} onClick={handleSubmit}>
            Confirm
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default TwillioConfiguration;

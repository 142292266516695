import { Pencil, Trash } from "lucide-react";
import Button from "../ui/Button";
import { Prompt } from "@/lib/types";

interface PromptTableProps {
  prompts: Prompt[];
  onEdit: (prompt: any) => void;
  setIsModalOpen: (e:any) => void;
}

export default function PromptTable({
  prompts,
  onEdit,
  setIsModalOpen,
}: PromptTableProps) {
  return (
    <div className="mt-8">
      <div className="mt-8 flow-root">
        <div className="-my-2 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                      Title
                    </th>
                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Description
                    </th>
                    <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Actions</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {prompts.map((p) => {
                    return (
                      <tr key={p.title + Math.random()}>
                        <td className="min-w-96 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {p?.title}
                        </td>
                        <td className="px-3 min-w-96 py-4 text-sm text-gray-500">
                          {p?.description}
                        </td>
                        {
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <div className="flex justify-end space-x-2">
                              <Button
                                onClick={() => onEdit(p)}
                                variant="outline"
                                size="sm"
                              >
                                <Pencil className="h-4 w-4" />
                              </Button>
                              <Button
                                onClick={() =>
                                  setIsModalOpen((prev:any) => ({
                                    ...prev,
                                    deleteId: p.id,
                                    deleteModal: true,
                                  }))
                                }
                                variant="outline"
                                size="sm"
                              >
                                <Trash className="h-4 w-4" />
                              </Button>
                            </div>
                          </td>
                        }
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { Prompt } from "@/lib/types";
import { MessageCircle } from "lucide-react";
import { Link } from "react-router-dom";

export default function PromptCard(props: Prompt) {
  return (
    <Link
      onClick={() => localStorage?.removeItem("threadId")}
      to={"/dashboard/playground/" + props.id}
      className="rounded-lg overflow-hidden shadow-lg bg-white hover:shadow-xl transition-shadow duration-300 ease-in-out cursor-pointer"
    >
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold text-gray-800">
            {props?.title}
          </h2>
          <MessageCircle className="min-w-8 min-h-8 w-8 h-8 text-blue-500 mr-2" />
        </div>
        <p className="text-gray-600 text-base">{props?.description}</p>
      </div>
    </Link>
  );
}

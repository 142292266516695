import React, { useEffect, useState } from "react";
import Button from "@/components/ui/Button";
import { sendSupportEmail } from "@/email template/generateEmailTemplate";
import { CustomToast } from "@/utils/helpers";
import ButtonLoader from "@/components/ui/ButtonLoader";
import { useAuth } from "@/context/AuthContext";
import clsx from "clsx";

const Support = () => {
  const { state: authState } = useAuth();
  const [state, setState] = useState({
    title: "",
    message: "",
  });
  const [loading, setLoading] = useState(true); // Track iframe loading state
  const [iframe, setIframe] = useState<any>();
  const [sendingMail, setSendingMail] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    if (!state.title || !state.message) return;
    e.preventDefault();
    try {
    setSendingMail(true);
      const res = await sendSupportEmail({
        message: state?.message,
        title: state?.title,
        sendingEmail: authState?.user?.email,
      });
      const result = await res.json();
      console.log("Response from Firebase Function:", result);

      if (res.status !== 200) {
        const errorText = await res.json();
        throw new Error(errorText.message);
      }
      CustomToast("success", "Request submitted successfully");
      setState({
        title: "",
        message: "",
      });
    } catch (error:any) {
      CustomToast("error", error?.message);
      console.log("Error sending mail", error);
    }finally{
      setSendingMail(false);
    }
  };

  return (
    <div className="mx-auto max-w-7xl">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Support</h1>
      </div>

      {/* <ButtonLoader height="60" width="60" /> */}
      <div className="w-full flex flex-col lg:flex-row items-start h-full lg:h-[calc(100vh-300px)] gap-10 mt-8 lg:mt-12">
        <div className="w-full lg:w-2/3 h-full relative">
          {loading && (
            <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-50">
              <div className="w-12 h-12 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
            </div>
          )}

          <iframe
            src="https://scribehow.com/embed/Victoria_AI_Super_Admin_Setup__eJ8C8hXOT_OG9YiiPpUXOw?"
            className={clsx("w-full h-[430px] sm:h-[780px] lg:h-full", loading && "hidden")}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen
            width="100%"
            height="auto"
            onLoad={() => setLoading(false)}
          ></iframe>
        </div>

        <div className="w-full lg:w-1/3 h-fit flex flex-col justify-center mx-auto bg-white p-6 rounded-lg">
          <h2 className="text-xl font-semibold text-gray-900">
            How can we help
          </h2>
          <form onSubmit={handleSubmit} className="w-full mt-3">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              placeholder="Title...."
              onChange={(e) => setState({ ...state, title: e.target.value })}
              value={state.title}
              className="outline-none mt-2 p-2 border w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              disabled={sendingMail}
            />

            <label
              htmlFor="message"
              className="block text-sm font-medium text-gray-700 mt-3"
            >
              Message
            </label>
            <textarea
              name="message"
              id="message"
              rows={8}
              onChange={(e) => setState({ ...state, message: e.target.value })}
              value={state.message}
              placeholder="Message..."
              className="outline-none mt-2 p-2 border w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
              disabled={sendingMail}
            ></textarea>
            <div className="w-full flex justify-end">
              <Button
                type="submit"
                className="mt-3"
                disabled={authState?.readOnlyUser !== null || sendingMail || !state.title || !state.message}
              >
                Submit
                {sendingMail && <ButtonLoader />}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Support;

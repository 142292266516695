import { useState } from "react";
import { useAuth } from "@/context/AuthContext";
import { useRequests } from "@/context/RequestsContext";
import { RequestComment, RequestAttachment, Request } from "@/lib/types";
import Button from "./ui/Button";
import { MessageSquare, Paperclip, Download } from "lucide-react";
import FileUpload from "./ui/FileUpload";

import { storage } from "@/lib/firebase/storage";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import useDownloader from "react-use-downloader";
import clsx from "clsx";
import { updateRequest } from "@/context/RequestsContext";
import { Input } from "antd";
import { insertNotification } from "@/lib/firebase/notification";
import { useAdmin } from "@/context/AdminContext";
import { User } from "@/lib/types";
import { doc, getDoc } from "firebase/firestore";
import { db } from "@/lib/firebase";
import { sendSms } from "@/api/sendSms";

const { TextArea } = Input;

const ATTACHMENT_PATH = "attachments";

interface RequestCommentsProps {
  requestId: string;
  comments: RequestComment[];
  originalAnalysis:
    | {
        aiResponse: string;
        category: string;
        title: string;
      }
    | undefined;
  request: Record<string, any>;
}

const sendNotificationForComment = async ({
  users,
  name,
  title,
  id,
  workspaceId,
}: {
  name: string;
  title: string;
  id: string;
  workspaceId: string;
  users: User[];
}) => {
  const allNotificationPromise: any = [];
  users.forEach((admin: any) => {
    allNotificationPromise.push(
      insertNotification({
        title: "Notes Added",
        message: `${name} added a comment on request: "${title}".`,
        userId: admin.id,
        requestId: id,
        workspaceId: workspaceId,
        key: "request",
      })
    );
  });

  try {
    await Promise.all(allNotificationPromise);
    console.log("send Notification For Attachments ended");
  } catch (error) {
    console.error("Failed to send notifications:", error);
  }
};
const sendNotificationForAttachments = async ({
  users,
  name,
  title,
  id,
  workspaceId,
}: {
  name: string;
  title: string;
  id: string;
  workspaceId: string;
  users: User[];
}) => {
  const allNotificationPromise: any = [];
  users.forEach((admin: any) => {
    allNotificationPromise.push(
      insertNotification({
        title: "Attachment Added",
        message: `${name} added an attachment on request: "${title}".`,
        userId: admin.id,
        requestId: id,
        workspaceId: workspaceId,
        key: "request",
      })
    );
  });

  try {
    await Promise.all(allNotificationPromise);
    console.log("send Notification For Attachments ended");
  } catch (error) {
    console.error("Failed to send notifications:", error);
  }
};

const sendNotificationForAttachmentsAndNotes = async ({
  users,
  name,
  title,
  id,
  workspaceId,
}: {
  name: string;
  title: string;
  id: string;
  workspaceId: string;
  users: User[];
}) => {
  const allNotificationPromise: any = [];
  users.forEach((admin: any) => {
    allNotificationPromise.push(
      insertNotification({
        title: "Notes and Attachment Added",
        message: `${name} added an attachment and notes  on request: "${title}".`,
        userId: admin.id,
        requestId: id,
        workspaceId: workspaceId,
        key: "request",
      })
    );
  });

  try {
    await Promise.all(allNotificationPromise);
    console.log("send Notification For Attachments ended");
  } catch (error) {
    console.error("Failed to send notifications:", error);
  }
};

export default function RequestComments({
  requestId,
  comments,
  originalAnalysis,
  request,
}: RequestCommentsProps) {
  const { state: requestState, dispatch } = useRequests();
  const { state: authState } = useAuth();
  const { state: adminState } = useAdmin();
  const [newComment, setNewComment] = useState("");
  const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  // const [isEditingNote, setIsEditingNote] = useState(false);
  const [editedNote, setEditedNote] = useState<string>(
    originalAnalysis?.aiResponse || ""
  );
  const [updatingNote, setUpdatingNote] = useState(false);

  const [showAll, setShowAll] = useState(false);
  const { size, elapsed, percentage, download, cancel, isInProgress } =
    useDownloader();


    const fetchAgent = async (id: string) => {
      try {
        if (adminState.users.find((user: any) => user.id === id)) return;
        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return docSnap.data() as User;
        }
      } catch (error) {
        console.error("Error fetching agent:", error);
      }
    };

  const handleFileSelect = (files: File[]) => {
    setSelectedFiles((prev) => [...prev, ...files]);
  };

  const handleFileRemove = (index: number) => {
    setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const uploadFiles = async (files: File[]): Promise<RequestAttachment[]> => {
    const fileUploadPromises = files.map(async (file) => {
      const fileRef = ref(storage, `${ATTACHMENT_PATH}/${file.name}`);
      const metadata = {
        contentType: file.type,
      };
      await uploadBytes(fileRef, file, metadata);
      const downloadURL = await getDownloadURL(fileRef);

      return {
        id: crypto.randomUUID(),
        name: file.name,
        type: file.type,
        size: file.size,
        url: downloadURL,
      };
    });

    return Promise.all(fileUploadPromises);
  };

  const handleAddComment = async () => {
    if ((!newComment.trim() && selectedFiles.length === 0) || !authState.user) {
      return;
    }

    setError(null);
    setIsUploading(true);

    try {
      // Upload any attached files
      const attachments =
        selectedFiles.length > 0 ? await uploadFiles(selectedFiles) : [];

      // Create the comment
      const comment: RequestComment = {
        userId: authState.user.id,
        id: crypto.randomUUID(),
        requestId,
        content: newComment.trim(),
        author: authState.user.name,
        attachments,
        createdAt: new Date().toISOString(),
      };

      // Find the request
      const request = requestState?.requests.find((r) => r.id === requestId);
      if (authState.user?.role === "AGENT" && request?.adminId) {
        await sendSms({
          body: {
            requestId: request?.id,
            to: request?.adminId,
            from: request?.workspaceId,
            message: `You have a new response from ${authState?.user?.name} for Request: ${request?.title}. See the response here: https://heyvictoria.ai/dashboard/requests?requestId=${request?.id}`,
          }
        })
      } else {
        await sendSms({
          body: {
            requestId: request?.id,
            to: request?.agentId,
            from: request?.workspaceId,
            message: `You have a new response from ${authState?.user?.name} for Request: ${request?.title}. See the response here: https://heyvictoria.ai/dashboard/requests?requestId=${request?.id}`,
          }
        })
      }
      const agent: any = await fetchAgent(request?.agentId as string)
      if (!request) {
        throw new Error("Request not found");
      }
      const users =[...adminState.users, agent]
        .concat(adminState?.assosicatedAdmins || [])
        .filter((admin: User) => {
          const isSuperAdmin = admin?.role === "SUPER_ADMIN";
          const isAssociatedAdmin =
            request.adminId === null &&
            admin?.categoryIds.includes(request?.categoryId as string) &&
            admin?.officeIds.includes(request?.officeId as string);
          const isAssignedAdmin = request?.adminId === admin?.id;
          const isAgent = request.agentId === admin?.id;

          return (
            (isSuperAdmin || isAssociatedAdmin || isAssignedAdmin || isAgent) &&
            admin?.id !== authState?.user?.id
          );
        })
        .filter(
          (admin: User, index: number, self: any) =>
            index === self.findIndex((a: User) => a.id === admin.id)
        );

      console.log({ users });
      if (selectedFiles?.length > 0 && newComment !== "") {  
      await sendNotificationForAttachmentsAndNotes({
          users,
          id: request?.id as string,
          title: request?.title as string,
          workspaceId: request?.workspaceId as string,
          name: authState?.user?.name as string,
        });
      }
      else if (selectedFiles.length > 0) {
        await sendNotificationForAttachments({
          users,
          id: request?.id as string,
          title: request?.title as string,
          workspaceId: request?.workspaceId as string,
          name: authState?.user?.name as string,
        });
      }  else {
        await sendNotificationForComment({
          users,
          id: request?.id as string,
          title: request?.title as string,
          workspaceId: request?.workspaceId as string,
          name: authState?.user?.name as string,
        });
      }
      // Add the comment
      dispatch({
        type: "ADD_COMMENT",
        payload: { requestId, comment },
      });

      // Clear the form
      setNewComment("");
      setSelectedFiles([]);
    } catch (error) {
      console.error("Error adding comment:", error);
      setError("Failed to add comment. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  const discardNote = async () => {
    const updatedRequest = {
      ...request,
      originalAnalysis: {
        ...request?.originalAnalysis,
        aiResponse: "",
      },
    };
    setUpdatingNote(true);
    try {
      const response = await updateRequest(updatedRequest);
      dispatch({
        type: "UPDATE_REQUEST",
        payload: updatedRequest as Request,
      })
      console.log({ response });
    } catch (error) {
      console.log("Error Occured in discarding note", error);
      setEditedNote(originalAnalysis?.aiResponse || "");
    }
    setUpdatingNote(false);
  };

  const releaseNote = async () => {
    if ((!editedNote.trim() && selectedFiles.length === 0) || !authState.user) {
      return;
    }

    setError(null);
    setUpdatingNote(true);

    try {
      // Upload any attached files
      const attachments =
        selectedFiles.length > 0 ? await uploadFiles(selectedFiles) : [];

      // Create the comment
      const comment: RequestComment = {
        userId: authState.user.id,
        id: crypto.randomUUID(),
        requestId,
        content: editedNote.trim(),
        author: authState.user.name,
        attachments,
        createdAt: new Date().toISOString(),
      };

      // Find the request
      if (!request) {
        throw new Error("Request not found");
      }
      await sendSms({
        body: {
          requestId: request?.id,
          to: request?.agentId,
          from: request?.workspaceId,
          message: `You have a new response from ${authState?.user?.name} for Request: ${request?.title}. AI Response: "${editedNote?.slice(0, 100).trim()}...". See the full response here: https://heyvictoria.ai/dashboard/requests?requestId=${request?.id}`,
        }
      })
      const agent: any = await fetchAgent(request.agentId as string)

        await updateRequest({
          ...request,
          status: request.status === "Pending" ?"In Progress":request?.status,
        }  as Request);
        const allNotificationPromise: any = [];

        const users: User[] = [...adminState.users, agent]
          .concat(adminState?.assosicatedAdmins || [])
          .filter((admin: User) => {
            const isSuperAdmin = admin?.role === "SUPER_ADMIN";
            const isAssociatedAdmin =
              request &&
              request.adminId === null &&
              admin?.categoryIds.includes(request?.categoryId as string) &&
              admin?.officeIds.includes(request?.officeId as string);
            const isAssignedAdmin = request?.adminId === admin?.id;
            const isAgent = request && request.agentId === admin?.id;

            return (
              (isSuperAdmin ||
                isAssociatedAdmin ||
                isAssignedAdmin ||
                isAgent) &&
              admin?.id !== authState?.user?.id
            );
          })
          .filter(
            (admin: User, index: number, self: any) =>
              index === self.findIndex((a: User) => a.id === admin.id)
          );

          users?.forEach((admin: any) => {
          allNotificationPromise.push(
            insertNotification({
              title: "Notes Added!",
              message: `${authState?.user?.name} added a comment on request: "${request?.title}".`,
              userId: admin.id,
              requestId: request?.id,
              workspaceId: request?.workspaceId || "",
              key: "request",
            })
          );
        });

        try {
          await Promise.all(allNotificationPromise);
        } catch (error) {
          console.error("Failed to send notifications:", error);
        }
      // Add the comment
      dispatch({
        type: "RELEASE_NOTE",
        payload: { requestId, comment, user: authState.user },
      });

      setSelectedFiles([]);
    } catch (error) {
      console.error("Error adding comment:", error);
      setError("Failed to add comment. Please try again.");
    } finally {
      setUpdatingNote(false);
    }
  };

  const isAiAssistance = originalAnalysis?.category === "AI Assistant";
  const isAdmin =
    (authState.user?.role === "ADMIN" || authState.user?.role === "VENDOR") || authState.user?.role === "SUPER_ADMIN";
  const isResponseEditable =
    !isAiAssistance && originalAnalysis?.aiResponse && isAdmin;

    const handleShowAll = () => {
      setShowAll(!showAll);
    };

    const commentsToShow = showAll ? comments : comments.slice(0, 5);
  
  const isCompleted = request?.status === "Completed";

  return (
    <div className="mt-6 space-y-4">
      <div className="flex items-center space-x-2">
        <MessageSquare className="h-5 w-5 text-gray-400" />
        <h3 className="text-sm font-medium text-gray-900">Processing Notes</h3>
      </div>
      {isAiAssistance && originalAnalysis?.aiResponse && (
        <>
          <div className="rounded-lg p-4 bg-blue-50">
            <div className="flex justify-between mb-1">
              <span className={`text-sm font-medium text-blue-900 `}>
                {"Victoria AI"}
              </span>
            </div>
            <TextArea
              autoSize
              value={originalAnalysis?.aiResponse}
              className={clsx(
                " rounded-lg px-0 !bg-transparent w-full resize-none !shadow-none border-none text-sm text-blue-800 "
              )}
            />
          </div>
        </>
      )}

      <div className="space-y-4">
        {/* {!isAiAssistance && originalAnalysis?.aiResponse && (
          <>
            <div className={` bg-gray-50 p-4`}>
            <span className="text-sm font-medium text-gray-900">
                AI response
              </span>
              <TextArea
                className={clsx(
                  "rounded-lg px-0 mt-1 text-sm text-gray-700 !bg-transparent w-full resize-none !shadow-none border-none",
                  isEditingNote ? "pointer-events-auto" : "pointer-events-none"
                )}
                value={editedNote}
                onChange={(e) => setEditedNote(e.target.value)}
                autoSize={true}
                disabled={updatingNote}
              />
              {isAdmin && (
                <div className="flex justify-end gap-3">
                  {isEditingNote ? (
                    <>
                      {" "}
                      <Button variant="outline" onClick={cancelEditNote} disabled={updatingNote}>
                        Cancel
                      </Button>
                      <Button onClick={saveEditNote} disabled={updatingNote}>Save</Button>
                    </>
                  ) : (
                    <Button onClick={() => setIsEditingNote(true)} disabled={updatingNote}>Edit</Button>
                  )}
                </div>
              )}
            </div>
          </>
        )} */}
        {commentsToShow.map((comment) => (
          <div key={comment.id} className={`rounded-lg p-4 bg-gray-50`}>
            <div className="flex justify-between flex-col sm:flex-row gap-1">
              <span className="text-sm font-medium text-gray-900">
                {authState?.user?.id === comment.userId
                  ? authState.user.name
                  : comment.author}
              </span>
              <span className="text-sm text-gray-500">
                {new Date(comment.createdAt).toLocaleString()}
              </span>
            </div>
            {/* <p className={`mt-1 text-sm text-gray-700`}>{comment.content}</p> */}
            <TextArea
              autoSize={true}
              value={comment?.content}
              className={clsx(
                "rounded-lg px-0 !bg-transparent w-full resize-none !shadow-none border-none text-sm pointer-events-none"
              )}
            />

            {comment.attachments && comment.attachments.length > 0 && (
              <div className="mt-3 space-y-2">
                <div className="flex items-center space-x-2 text-sm text-gray-500">
                  <Paperclip className="h-4 w-4" />
                  <span>Attachments:</span>
                </div>
                <div className="space-y-2">
                  {comment.attachments.map((file) => (
                    <div
                      key={file.id}
                      className="flex flex-col sm:flex-row gap-2 items-center justify-between rounded-md bg-white p-2 text-sm"
                    >
                      <div className="flex flex-col sm:flex-row items-center space-x-2 grow w-full">
                        <TextArea
                          className="font-medium w-full border-none"
                          value={file.name}
                          autoSize={true}
                        />
                        <span className="text-gray-500 shrink-0">
                          ({(file.size / 1024).toFixed(1)} KB)
                        </span>
                      </div>
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          download(file.url, file.name);
                        }}
                        className="text-blue-600 hover:text-blue-700 cursor-pointer"
                      >
                        <Download className="h-4 w-4" />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
        <div className="flex items-center justify-center">
          {comments.length > 5 && (
            <Button onClick={handleShowAll} variant="ghost">
              {showAll ? "Show Less" : "Show All"}
            </Button>
          )}
        </div>
      </div>

      <div className="mt-4 space-y-4">
        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <p className="text-sm text-red-700">{error}</p>
          </div>
        )}
        {isResponseEditable && !isCompleted ? (
          <div className="p-3 border border-gray-200 rounded-lg">
            <TextArea
              className={clsx(
                "disabled:text-black rounded-lg px-0 !bg-transparent w-full resize-none !shadow-none border-none text-sm "
              )}
              placeholder="Add a note about the progress..."
              value={editedNote}
              onChange={(e) => setEditedNote(e.target.value)}
              autoSize={true}
              disabled={authState?.readOnlyUser !== null || updatingNote}
            />
          </div>
        ) : (
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a note about the progress..."
            className=" px-3 pt-3 pb-2 w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            rows={3}
            disabled={authState?.readOnlyUser !== null || isUploading}
          />
        )}

        {authState?.readOnlyUser === null && (
          <FileUpload
            onFileSelect={handleFileSelect}
            onRemove={handleFileRemove}
            selectedFiles={selectedFiles}
            accept=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png"
            maxSize={5 * 1024 * 1024} // 5MB
          />
        )}

        {isResponseEditable && !isCompleted ? (
          <div className="flex justify-end gap-2">
            <Button
              onClick={discardNote}
              disabled={
                authState?.readOnlyUser !== null ||
                updatingNote ||
                ((authState.user?.role === "ADMIN" || authState.user?.role === "VENDOR") && request?.adminId === null)
              }
            >
              Discard Note
            </Button>
            <Button
              onClick={releaseNote}
              disabled={
                authState?.readOnlyUser !== null ||
                ((authState.user?.role === "ADMIN" || authState.user?.role === "VENDOR") &&
                  request?.adminId === null) ||
                updatingNote ||
                (!editedNote.trim() && selectedFiles.length === 0)
              }
            >
              Approve
            </Button>
          </div>
        ) : (
          <div className="flex justify-end">
            <Button
              onClick={handleAddComment}
              disabled={
                authState?.readOnlyUser !== null ||
                (!newComment.trim() && selectedFiles.length === 0) ||
                !authState.user ||
                isGeneratingResponse ||
                isUploading
              }
            >
              {isUploading
                ? "Uploading..."
                : isGeneratingResponse
                ? "Generating Response..."
                : "Add Note"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

import Modal from "../ui/Modal";
import Button from "../ui/Button";
import { Prompt } from "@/lib/types";
import { useEffect, useState } from "react";
import { Select, Space } from "antd";

interface PromptFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  editingPrompt: any;
  prompts: Prompt[];
  error: string | null;
  loading: boolean;
  isEdit: boolean;
  setEditingPrompt: React.Dispatch<React.SetStateAction<any>>;
}

export const PromptForm: React.FC<PromptFormProps> = ({
  isOpen,
  onClose,
  onSubmit,
  editingPrompt,
  prompts,
  error,
  loading,
  isEdit,
  setEditingPrompt,
}) => {
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  useEffect(() => {
    const prompt = prompts?.find((node) => node?.id === editingPrompt?.id);
    if (
      editingPrompt?.description !== prompt?.description ||
      editingPrompt?.title !== prompt?.title ||
      editingPrompt?.disabled !== prompt?.disabled
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [editingPrompt, prompts]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? "Edit Prompt" : "Add Prompt"}
    >
      {error && (
        <div className="rounded-md bg-red-50 p-4 break-words">
          <p className="text-sm text-red-700">{error}</p>
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="title"
            className="block text-sm font-medium text-gray-700"
          >
            Title
          </label>
          <input
            value={editingPrompt?.title}
            type="text"
            id="title"
            onChange={(e) =>
              setEditingPrompt({ ...editingPrompt, title: e.target.value })
            }
            className="mt-1 px-1 py-2 lock w-full rounded-md border-border-default shadow-sm focus:border-blue-antd focus-visible:border-blue-antd focus-visible:outline-0   border focus:ring-blue-antd sm:text-sm"
            required
          />
        </div>
        <div>
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <textarea
            value={editingPrompt?.description}
            onChange={(e) =>
              setEditingPrompt({
                ...editingPrompt,
                description: e.target.value,
              })
            }
            id="description"
            rows={3}
            className="mt-2 px-4 py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm border"
            required
          />
        </div>
        <div>
          <label
            htmlFor="visibility"
            className="block text-sm font-medium text-gray-700"
          >
            Visibility
          </label>
          <Space className="w-full" direction="vertical">
            <Select
             id="visibility"
              disabled={loading}
              allowClear
              className="w-full mt-1"
              placeholder="Select Visibility"
              value={(!editingPrompt?.disabled) ? 'Enabled' : 'Disabled'}
              defaultValue={editingPrompt?.disabled}
              onChange={(value) => {
                setEditingPrompt((prev: any) => ({ ...prev, disabled: value }));
              }}
              options={[
                { label: "Enable", value: false },
                { label: "Disable", value: true },
              ]}
            />
          </Space>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <Button
            disabled={
              (isEdit
                ? disabled
                : editingPrompt?.description === "" ||
                  editingPrompt?.title === "" ||
                  editingPrompt === null ||
                  !editingPrompt?.description ||
                  !editingPrompt?.title) || loading
            }
            type="submit"
            className="w-full sm:ml-3 sm:w-auto"
          >
            {loading ? "Submitting" : isEdit ? "Update" : "Create"}
          </Button>
          <Button
            onClick={onClose}
            type="button"
            variant="outline"
            className="mt-3 w-full sm:mt-0 sm:w-auto"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

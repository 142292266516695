import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import { UserRole } from '@/lib/types';

interface RequireRoleProps {
  children: ReactNode;
  roles: any;
}

export default function RequireRole({ children, roles }: RequireRoleProps) {
  const { state } = useAuth();
  const location = useLocation();
  const user = state?.readOnlyUser !== null ? state?.readOnlyUser :  state?.user 

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!roles.includes(user?.role)) {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
}
import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
  useState,
} from "react";
import { User } from "@/lib/types";
import { signIn, signOut } from "@/lib/firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  auth,
} from "@/lib/firebase/app";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "@/lib/firebase/app";
import { useLocation, useNavigate } from "react-router-dom";
import { generateEmailVerificationEmailFunction } from "@/email template/generateEmailVerificationEmail";
import { useCookies } from "react-cookie";

interface AuthState {
  user: User | null;
  readOnlyUser: User | null;
  isAuthenticated: boolean;
  loading: boolean;
  error: string | null;
  token: string;
}

type AuthAction =
  | { type: "LOGIN"; payload: User }
  | { type: "LOGOUT" }
  | { type: "UPDATE_USER"; payload: User }
  | { type: "SET_LOADING" }
  | { type: "SET_ERROR"; payload: string | null }
  | { type: "UNSET_ERROR" }
  | { type: "SET_READ_ONLY_USER"; payload: User }
  | { type: "UNSET_READ_ONLY_USER"};

const initialState: AuthState = {
  user: null,
  readOnlyUser: null,
  isAuthenticated: false,
  loading: true,
  error: null,
  token: "",
};

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: {
          ...action.payload,
          accountStatus: action?.payload?.accountStatus || "ACTIVE",
          disabled: action.payload.disabled ?? false,
        },
        isAuthenticated: true,
        loading: false,
        error: null,
      };

    case "LOGOUT":
      return {
        ...initialState,
        error: state?.error ? state.error : null,
        loading: false,
      };

    case "UPDATE_USER":
      if (state?.readOnlyUser !== null) {
        return {
          ...state,
          readOnlyUser: {
            ...state.readOnlyUser,
            ...action.payload,
          },
        };
      }
      return {
        ...state,
        user: state.user ? { ...state.user, ...action.payload } : null,
      };

    case "SET_READ_ONLY_USER":
      return {
        ...state,
        readOnlyUser: { ...action.payload },
      };
    case "UNSET_READ_ONLY_USER":
      return {
        ...state,
        readOnlyUser: null,
      };

    case "SET_LOADING":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "SET_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "UNSET_ERROR":
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}

interface AuthContextType {
  state: AuthState;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  updateUser: (user: User) => Promise<void>;
  insertUser: (user: User) => Promise<void>;
  deleteUser: (userId: string) => Promise<void>;
  getToken: () => Promise<string | undefined>;
  dispatch: React.Dispatch<AuthAction>;
  addReadOnlyUser: (user: User) => Promise<void>;
  removeReadOnlyUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);
export function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies(['appilix_push_notification_user_identity'])

  useEffect(() => {
    dispatch({
      type: "UNSET_ERROR",
    });
  }, [location]);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        try {
          if (!firebaseUser.emailVerified) {
            dispatch({
              type: "SET_ERROR",
              payload: "Please verify your email before logging in.",
            });
            await generateEmailVerificationEmailFunction({
              name: firebaseUser.displayName || "",
              email: firebaseUser.email || "",
              id: firebaseUser.uid,
            });
            logout();
            return;
          }
          console.log(firebaseUser);
          const userDoc = await getDoc(doc(db, "users", firebaseUser.uid));

          if (userDoc.exists()) {
            let userData = userDoc.data();
            if (!userData.openAiKey && userData?.role === "AGENT") {
              const q1 = query(
                collection(db, "users"),
                where("role", "==", "SUPER_ADMIN"),
                where("workspaceId", "==", userData?.workspaceId)
              );
              const querySnapshot1 = await getDocs(q1);
              const data = querySnapshot1.docs.map((doc) =>
                doc.data()
              )[0] as User;
              userData = {
                ...userData,
                openAiKey: data?.openAiKey,
              };
            }
            const user = {
              id: userDoc.id,
              ...userData,
              emailVerified: firebaseUser.emailVerified,
            } as User;

            if (userDoc.id) {
              setCookie('appilix_push_notification_user_identity', userDoc.id, {path: '/'});
            }
            dispatch({
              type: "LOGIN",
              payload: user,
            });
            navigate(location?.pathname + location?.search);
          } else {
            console.log("No user data found in Firestore");
            dispatch({ type: "LOGOUT" });
            removeCookie('appilix_push_notification_user_identity', {path: '/'});
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          dispatch({ type: "LOGOUT" });
          removeCookie('appilix_push_notification_user_identity', {path: '/'});
        }
      } else {
        dispatch({ type: "LOGOUT" });
        removeCookie('appilix_push_notification_user_identity', {path: '/'});
      }
    });

    return () => unsubscribe();
  }, []);

  const getToken = async () => {
    const auth = getAuth();
    const token = await auth?.currentUser?.getIdToken();
    return token;
  };

  const login = async (email: string, password: string) => {
    try {
      return await signIn(email, password);
    } catch (error) {
      console.error("Login error:", error);
      dispatch({
        type: "SET_ERROR",
        payload: error instanceof Error ? error.message : "Failed to sign in",
      });
      throw error;
    }
  };

  const logout = async () => {
    try {
      await signOut();
      dispatch({ type: "LOGOUT" });
      removeCookie('appilix_push_notification_user_identity', {path: '/'});
    } catch (error) {
      console.error("Logout error:", error);
      throw error;
    }
  };

  const updateUser = async (user: User) => {
    try {
      const userRef = doc(db, "users", user.id);
      await updateDoc(userRef, user as any);
      console.log("User updated successfully in Firestore");
    } catch (error) {
      console.error("Error updating user data in Firestore:", error);
      dispatch({
        type: "SET_ERROR",
        payload: "Error updating user data. Please try again later.",
      });
    }
  };

  const insertUser = async (user: User) => {
    try {
      const userRef = doc(db, "users", user.id);
      await setDoc(userRef, user);
      console.log("User inserted successfully into Firestore");
    } catch (error: any) {
      console.error("Error inserting user data into Firestore:", error.message);
      dispatch({
        type: "SET_ERROR",
        payload: "Error inserting user data. Please try again later.",
      });
    }
  };

  const deleteUser = async (userId: string) => {
    try {
      const userRef = doc(db, "users", userId);
      await deleteDoc(userRef);
      console.log("User deleted successfully from Firestore");
    } catch (error: any) {
      console.error("Error deleting user data from Firestore:", error.message);
      dispatch({
        type: "SET_ERROR",
        payload: "Error deleting user data. Please try again later.",
      });
    }
  };

  const addReadOnlyUser = async (user: User) => {
    console.log({user});
    dispatch({ type: "SET_READ_ONLY_USER", payload: user });
  };

  const removeReadOnlyUser = async () => {
    dispatch({ type: "UNSET_READ_ONLY_USER" });
    if (state.readOnlyUser?.role === "ADMIN") {
      navigate("/dashboard/admin/admins");
    } else if (state.readOnlyUser?.role === "AGENT") {
      navigate("/dashboard/admin/agents");
    } else if (state.readOnlyUser?.role === "VENDOR") {
      navigate("/dashboard/admin/vendors");
    } else {
      navigate("/dashboard/admin/super-admins");
    }
  };


  return (
    <AuthContext.Provider
      value={{
        state,
        login,
        logout,
        updateUser,
        insertUser,
        deleteUser,
        getToken,
        dispatch,
        addReadOnlyUser,
        removeReadOnlyUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

import EmptyState from "@/components/prompts/EmptyState";
import { PromptForm } from "@/components/prompts/PromptForm";
import PromptTable from "@/components/prompts/PromptTable";
import Button from "@/components/ui/Button";
import ButtonLoader from "@/components/ui/ButtonLoader";
import Modal from "@/components/ui/Modal";
import { useAdmin } from "@/context/AdminContext";
import { useAuth } from "@/context/AuthContext";
import { db } from "@/lib/firebase";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { useEffect, useState } from "react";

export default function Prompts() {
  const { state, dispatch } = useAdmin();
  const { state: auth } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState({
    modal: false,
    isEdit: false,
    deleteModal: false,
    deleteId: "",
  });
  const [editingPrompt, setEditingPrompt] = useState<any | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const user = auth?.readOnlyUser || auth?.user;
  console.log({ state });

  const handleSubmit = async () => {
    const prompt: any = {
      title: editingPrompt.title,
      description: editingPrompt.description,
      createdAt: editingPrompt?.createdAt || new Date().toISOString(),
      workspaceId: user?.id,
      disabled: editingPrompt?.disabled || false,
    };
    setIsSubmitting(true);
    try {
      try {
        if (!user?.id) throw new Error("User ID is required");
        const promptRef = doc(collection(db, "users", user?.id, "prompts"));
        const newPrompt = {
          ...prompt,
          id: promptRef.id,
        };
        if (!isModalOpen?.isEdit) {
          await setDoc(promptRef, newPrompt);
        } else {
          const promptRef = doc(
            db,
            "users",
            user?.id,
            "prompts",
            editingPrompt?.id
          );
          await updateDoc(promptRef, prompt);
          dispatch({
            type: "UPDATE_PROMPT",
            payload: prompt,
          });
        }
        setIsModalOpen((prev) => ({ ...prev, modal: false }));
        return { success: true };
      } catch (error: any) {
        console.error("Error adding prompt:", error);
        return { success: false, error: error?.message };
      }
    } catch (error) {
      console.error("Failed to add category to Firebase:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleEdit = (prompt: any) => {
    setEditingPrompt(prompt);
    setIsModalOpen((prev) => ({ ...prev, modal: true, isEdit: true }));
  };

  const handleDelete = async (id: string) => {
    try {
      setIsSubmitting(true);
      if (!id) throw new Error("User ID and Prompt ID are required");
      const promptRef = doc(db, "users", user?.id as any, "prompts", id);
      await deleteDoc(promptRef);
      setIsModalOpen((prev) => ({ ...prev, deleteModal: false, deleteId: "" }));
      return { success: true, id: id };
    } catch (error: any) {
      console.error("Error deleting prompt:", error);
      return { success: false, error: error.message };
    } finally {
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="mx-auto max-w-7xl">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Playground Prompts</h1>
        <Button
          onClick={() => {
            setEditingPrompt(null);
            setIsModalOpen((prev) => ({ ...prev, modal: true, isEdit: false }));
          }}
        >
          Add Prompt
        </Button>
      </div>
      {state?.prompts?.length === 0 ? (
        <EmptyState />
      ) : (
        <PromptTable
          setIsModalOpen={setIsModalOpen}
          prompts={state?.prompts}
          onEdit={handleEdit}
        />
      )}
      <PromptForm
        editingPrompt={editingPrompt}
        error={error}
        isEdit={isModalOpen.isEdit}
        isOpen={isModalOpen.modal}
        loading={isSubmitting}
        onClose={() => {
          setIsModalOpen((prev) => ({ ...prev, modal: false }));
          setError(null);
        }}
        onSubmit={handleSubmit}
        prompts={state?.prompts}
        setEditingPrompt={setEditingPrompt}
        key="prompts"
      />
      {isModalOpen.deleteModal && (
        <Modal
          isOpen={isModalOpen.deleteModal}
          onClose={() =>
            setIsModalOpen((prev) => ({
              ...prev,
              deleteModal: false,
              deleteId: "",
            }))
          }
          title={""}
        >
          {error && (
            <div className="rounded-md bg-red-50 p-4 mt-5 mb-5">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          )}
          <p>Are you sure you want to delete this prompt?</p>
          <div className="flex items-center justify-end gap-3 mt-4">
            <Button
              onClick={() => {
                setIsModalOpen((prev) => ({
                  ...prev,
                  deleteModal: false,
                  deleteId: "",
                }));
              }}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDelete(isModalOpen?.deleteId);
              }}
              disabled={isSubmitting}
            >
              Confirm
              {isSubmitting && <ButtonLoader />}
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
}

import React from "react";
import { ExternalLinkIcon } from "lucide-react";
import { Link } from "react-router-dom";

type CardProps = {
  title: string;
  onViewClick: () => void;
  email: string;
  phoneNumber?: string;
  id?: string;
};

const WorkspaceCard: React.FC<CardProps> = ({
  title,
  onViewClick,
  email,
  phoneNumber,
  id
}) => {
  return (
    <Link
      id={id}
      to={`/dashboard`}
      onClick={onViewClick}
      className="lg:max-w-sm w-full block mx-auto bg-white duration-150 ease-in hover:shadow-md rounded-lg overflow-hidden border border-gray-200 group"
    >
      <div className="p-4">
        <div className="w-full">
          <div>
            <div className="flex items-center justify-end w-full">
              <ExternalLinkIcon className="text-white text-lg h-8 w-8 rounded-full bg-blue-700 p-2" />
            </div>
            <div className="-mt-5">
              <h2 className="lg:text-lg text-base font-bold text-gray-800 capitalize max-w-[190px] truncate">
                {title}
              </h2>
              <p className="text-sm text-gray-600 mt-1">Email: {email}</p>
              <p className="text-sm text-gray-600">
                Phone: {phoneNumber ? phoneNumber : `-`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default WorkspaceCard;

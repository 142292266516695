import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";
import { useRequests } from "@/context/RequestsContext";
import { useAdmin } from "@/context/AdminContext";
import { useNotifications } from "@/context/NotificationContext";
import Button from "@/components/ui/Button";
import VoiceRecorder from "@/components/VoiceRecorder";
// import { Category } from '@/lib/types';
import { analyzeTranscript, getAssistantResponse } from "@/lib/openai";
import { createRequest } from "@/lib/requests";
import { ValidationError, RequestError } from "@/lib/errors";
import { extractFileName, generateId } from "@/lib/utils";
import { User } from "@/types";
import { capitilizeText } from "@/utils/helpers";
import { deleteObject, getStorage, ref } from "firebase/storage";

export default function NewRequest() {
  const navigate = useNavigate();
  const {
    state: { user },
  } = useAuth();
  const { state: adminState } = useAdmin();
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState<any | null>(null);
  const [recording, setRecording] = useState<string | null>(null);
  const [transcript, setTranscript] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [voice, setVoice] = useState<boolean | undefined>(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [uploadUrl, setUploadUrl] = useState("");

  const [recordingDuration, setRecordingDuration] = useState<
    number | undefined
  >(0);
  const [originalAnalysis, setOriginalAnalysis] = useState<{
    title: string;
    category: string;
    aiResponse: string;
  } | null>(null);
  const [whisperData, setWhisperData] = useState<{
    confidence: number;
    segments?: Array<{ text: string; confidence: number }>;
  } | null>(null);
  console.log(adminState);

  const handleRecordingComplete = async ({
    uploadUrl,
    transcript,
    whisperData,
    isAiCategory,
    recordingDuration,
  }: {
    uploadUrl: string;
    transcript: string;
    whisperData?: {
      confidence: number;
      segments?: Array<{ text: string; confidence: number }>;
    };
    isAiCategory?: boolean;
    recordingDuration?: number;
  }) => {
    try {
      if (!transcript?.trim()) {
        throw new ValidationError("No speech detected. Please try again.");
      }
      setIsAnalyzing(true);
      setVoice(isAiCategory);
      setRecording(uploadUrl);
      setTranscript(transcript);
      setWhisperData(whisperData || null);
      setError(null);

      const analysis = await analyzeTranscript(
        transcript,
        adminState?.categories,
        user?.openAiKey
      );

      if (!analysis.title || !analysis.category) {
        throw new ValidationError("Invalid analysis result");
      }
      console.log({ analysis });

      // Find the category object that matches the analyzed category name
      const selectedCategory = adminState.categories.find(
        (c: any) =>
          c.name.trim().toLowerCase() ===
          (analysis.category === "AI Assistant"
            ? "Uncategorized"
            : analysis.category.split(",")[0]
          )
            .trim()
            .toLowerCase()
      );

      if (!selectedCategory) {
        throw new ValidationError(
          `Category "${analysis.category.split(",")[0]}" not found`
        );
      }

      setTitle(analysis.title);
      setCategory(
        isAiCategory || transcript?.toLowerCase()?.includes("victoria")
          ? {
              id:
                adminState?.categories?.find(
                  (c: any) =>
                    c?.name?.trim()?.toLowerCase() ===
                    "AI Assistant".toLowerCase()
                )?.id || generateId(),
              createdAt: selectedCategory
                ? selectedCategory?.createdAt
                : new Date(),
              description: selectedCategory
                ? selectedCategory?.description
                : "AI Assistant",
              name: "AI Assistant",
            }
          : selectedCategory
      );
      setOriginalAnalysis({
        title: analysis.title,
        category:
          isAiCategory || transcript.toLowerCase().includes("victoria")
            ? "AI Assistant"
            : analysis.category === "AI Assistant"
            ? "Uncategorized"
            : analysis.category.split(",")[0],
        aiResponse: analysis?.aiResponse,
      });
      setRecordingDuration(recordingDuration);
    } catch (error) {
      console.error("Analysis error:", error);
      setTitle("");
      setCategory(null);
      setError(
        error instanceof Error ? error.message : "Failed to analyze recording"
      );
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);
    const isAi =  voice || transcript.toLowerCase().includes("victoria")
    try {
      if (!user) {
        throw new ValidationError("Please log in to submit a request");
      }

      if (!title.trim()) {
        throw new ValidationError("Title is required");
      }

      if (!category) {
        throw new ValidationError("Category is required");
      }

      if (!recording) {
        throw new ValidationError("Recording is required");
      }

      if (!transcript.trim()) {
        throw new ValidationError("No speech detected in recording");
      }
      const relevantAdmins = adminState?.assosicatedAdmins?.filter(
        (admin: any) =>
          admin.categoryIds.includes(category.id) &&
          admin.officeIds.includes(user.officeIds[0])
      );

      const adminId =
        relevantAdmins?.length === 1 ? relevantAdmins[0].id : null;
      console.log({ adminId });

      const request = {
        title: title.trim(),
        category: category.name,
        categoryId: category.id,
        transcript: transcript.trim(),
        audioUrl: recording,
        agentId: user.id,
        officeId: user.officeIds[0],
        whisperData,
        originalAnalysis,
        createdAt: new Date().toISOString(),
        comments: [],
        recordingDuration: Math.floor(recordingDuration || 0),
      };

      const { request: savedRequest } = (await createRequest({
        ...request,
        adminId: isAi ? null : adminId,
        status:
        isAi
            ? "Completed"
            : "Pending",
        workspaceId: user?.workspaceId,
        user,
        admins: adminState.assosicatedAdmins as User[],
        whisperData: request?.whisperData ?? undefined,

        originalAnalysis: request?.originalAnalysis
          ? {
              title: request.originalAnalysis.title || "",
              category:
              isAi
                  ? "AI Assistant"
                  : (request.originalAnalysis.category as any),
              aiResponse: "",
            }
          : undefined,
      })) as any;
      // Add request to context
      // requestDispatch({ type: "ADD_REQUEST", payload: savedRequest });
      // Navigate to requests page
      navigate("/dashboard/requests", {
        state: { requestId: savedRequest?.id },
        replace: true,
      });
    } catch (error) {
      console.error("Error submitting request:", error);
      if (error instanceof ValidationError || error instanceof RequestError) {
        setError(error.message);
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handelClear = async () => {
    await deleteFile({ urlRef: uploadUrl });

    setCategory(null);
    setRecording("");
    setTitle("");

    setIsSubmitting(false);
    setRecording("");
    setTranscript("");
    setVoice(false);
    setOriginalAnalysis(null);
  };

  const deleteFile = async ({ urlRef }: { urlRef: string }) => {
    try {
      const fileName = extractFileName(urlRef);
      if (!fileName) {
        console.error("Could not extract file name");
        return;
      }

      const storage = getStorage();
      const desertRef = ref(storage, `audio/${user?.id}/${fileName}`);
      await deleteObject(desertRef);
      setUploadUrl("");
      console.log("Perform deletion");
      return;
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const isLoading = isSubmitting || isAnalyzing;
  console.log({ adminState });

  return (
    <div className="relative">
      <div className="mx-auto max-w-2xl">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-2xl font-semibold text-gray-900">New Request</h1>
        </div>

        <form onSubmit={handleSubmit} className="space-y-8">
          {error && (
            <div className="rounded-md bg-red-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg
                    className="h-5 w-5 text-red-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">{error}</h3>
                </div>
              </div>
            </div>
          )}

          <div className="space-y-6">
            <div>
              <label className="block text-lg font-medium text-gray-900 mb-4">
                Record Your Request
              </label>
              <VoiceRecorder
                onRecordingComplete={handleRecordingComplete}
                setIsAnalyzing={setIsAnalyzing}
                title={title}
                setUploadUrl={setUploadUrl}
                uploadUrl={uploadUrl}
                deleteFile={deleteFile}
              />
            </div>

            {originalAnalysis && (
              <div className="rounded-lg border border-gray-200 bg-white p-4">
                <h3 className="text-sm font-medium text-gray-700 mb-2">
                  AI Analysis
                </h3>
                <p className="text-sm text-gray-900">
                  Title: {originalAnalysis.title}
                  <br />
                  Category: {originalAnalysis.category}
                </p>
              </div>
            )}

            <div>
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                Request Title
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="mt-2 px-4 py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              />
            </div>

            <div>
              <label
                htmlFor="category"
                className="block text-sm font-medium text-gray-700"
              >
                Category
              </label>
              <select
                id="category"
                value={category?.id || ""}
                onChange={(e) => {
                  const selectedCategory = adminState.categories.find(
                    (c: any) => c.id === e.target.value
                  );
                  setCategory(selectedCategory || null);
                }}
                className="mt-2 px-4 py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                required
              >
                <option value="">Select a category</option>
                {adminState.categories
                  .sort((a: any, b: any) => a.name.localeCompare(b.name))
                  .map((cat: any) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>

          {/* <Dictaphone/> */}

          <div className="flex justify-between space-x-2 md:space-x-4">
            <Button
              type="button"
              variant="outline"
              className="h-8 px-3 text-sm sm:h-10 sm:px-4 sm:text-base"
              onClick={() => navigate("/dashboard/requests")}
            >
              Back
            </Button>

            <div className="flex justify-between space-x-2 md:space-x-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => handelClear()}
                className="h-8 px-3 text-sm sm:h-10 sm:px-4 sm:text-base"
              >
                Clear
              </Button>
              <Button
                type="submit"
                disabled={
                  !title ||
                  !category ||
                  !recording ||
                  isSubmitting ||
                  isAnalyzing
                }
                className="h-8 px-3 text-sm sm:h-10 sm:px-4 sm:text-base"
              >
                {isSubmitting
                  ? "Submitting..."
                  : isAnalyzing
                  ? "Analyzing..."
                  : "Submit Request"}
              </Button>
            </div>
          </div>
        </form>
      </div>

      {isLoading && (
        <div className=" h-full w-full flex justify-center items-center absolute top-0 left-0 bg-white bg-opacity-20">
          <span
            className="authentication-loader
authentication-loader scale-150"
          ></span>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Loader2, Menu, Plus, Send, X } from "lucide-react"; // Import a send icon from lucide-react
import { openai } from "@/lib/openai/client";
import {
  fetchThreadMessages,
  fetchUserThreads,
  sendMessageToAssistant,
} from "@/lib/openai";
import { useAuth } from "@/context/AuthContext";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "@/lib/firebase";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAdmin } from "@/context/AdminContext";
import clsx from "clsx";
import { useScreenSize } from "@/hooks/useScreenSize";
import { Skeleton } from "antd";
import styles from "./prompt-gallery.module.css";

const ChatPlayground = () => {
  const [messages, setMessages] = useState<{ role: string; content: string }[]>(
    []
  );
  const [input, setInput] = useState("");
  const [threadLoading, setThreadLoading] = useState(false);
  const [threads, setThreads] =
    useState<{ threadId: string; createdAt: string; title: string }[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [chatsLoading, setChatsLoading] = useState(false);
  const [threadId, setThreadId] = useState<string | null>(null);
  const [selectedThreadId, setSelectedThreadId] = useState<string | null>(null);
  const [collapsed, setCollapsed] = useState<boolean | null>(null);
  const { state } = useAuth();
  const { state: adminstate } = useAdmin();
  const screenSize = useScreenSize();
  const user = state?.readOnlyUser || state?.user;
  const defaultKeys = {
    aiAssistantId: "asst_jvyHhsDHaERuTSzq8Wae0k9P",
    openAiKey:
      "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",
  };
  let aiAssistantId = defaultKeys?.aiAssistantId;
  let openAiKey = defaultKeys?.openAiKey;
  const navigate = useNavigate();
  const { id } = useParams();

  const loadMessages = async (threadId: string) => {
    try {
      setChatsLoading(true);
      const chatMessages = await fetchThreadMessages(threadId, openAiKey);
      setMessages(chatMessages);
    } catch (error) {
      console.error("Error loading messages:", error);
    } finally {
      setChatsLoading(false);
    }
  };

  useEffect(() => {
    const loadThreads = async () => {
      if (user?.id) {
        try {
          setThreadLoading(true);
          const userThreads = await fetchUserThreads(user.id, id as any);
          const prompt = adminstate?.prompts.find((p) => p?.id === id);
          const exists = userThreads?.some((node) =>
            prompt?.description?.includes(node?.title as string)
          );
          if (!exists) {            
            handleSendMessage(prompt?.description);
          }else{
            setInput(prompt?.description as string);
          }

          setThreads(userThreads);
        } catch (error) {
          console.error("Error loading threads:", error);
        } finally {
          setThreadLoading(false);
        }
      }
    };

    loadThreads();
  }, [user?.id]);

  useEffect(() => {
    const initializeChats = async () => {
      let storedThreadId = localStorage.getItem("threadId");
      if (storedThreadId) {
        setThreadId(storedThreadId);
        await loadMessages(storedThreadId);
      }
    };

    initializeChats();
  }, [user?.id, selectedThreadId]);

  useEffect(() => {
    if (screenSize < 1024) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [location.pathname, screenSize]);

  useEffect(() => {
    const chatWrapper = document.getElementById("chatWrapper");
    if (chatWrapper) {
      chatWrapper.scrollTop = chatWrapper.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = async (prompt?: string) => {
    if (prompt ? !prompt.trim() : !input.trim()) return;

    setIsLoading(true);
    setMessages((prev) => [
      ...prev,
      { role: "user", content: prompt ? prompt : input },
    ]);
    setInput("");
    try {
      let activeThreadId: any = threadId;

      // ✅ Create a new thread only if it doesn't exist
      if (!threadId) {
        const thread = await openai.beta.threads.create();
        activeThreadId = thread.id;
        setThreadId(thread.id);
        setThreads((prev) => [
          ...(prev || []),
          {
            threadId: thread.id,
            createdAt: new Date().toISOString(),
            title: prompt
              ? prompt?.split(" ").slice(0, 5).join(" ")
              : input?.split(" ").slice(0, 5).join(" "),
          },
        ]);
        localStorage.setItem("threadId", thread.id);

        // Save thread to Firebase
        const userId = user?.id || "";
        const threadRef = doc(
          collection(db, `users/${userId}/threads`),
          thread.id
        );
        await setDoc(threadRef, {
          promptId: id,
          threadId: thread.id,
          title: prompt
            ? prompt?.split(" ").slice(0, 5).join(" ")
            : input?.split(" ").slice(0, 5).join(" "),
          createdAt: new Date().toISOString(),
        });
        // ✅ Update local state
        setThreads((prev) =>
          prev?.map((t) =>
            t.threadId === activeThreadId
              ? {
                  ...t,
                  title: prompt
                    ? prompt?.split(" ").slice(0, 5).join(" ")
                    : input?.split(" ").slice(0, 5).join(" "),
                }
              : t
          )
        );
      }
      const message = prompt ? prompt : input;
      // ✅ Send message to the assistant
      const assistantResponse = await sendMessageToAssistant(
        activeThreadId,
        message,
        aiAssistantId as string,
        openAiKey
      );

      if (assistantResponse) {
        setMessages((prev) => [
          ...prev,
          { role: "assistant", content: assistantResponse },
        ]);
      } else {
        throw new Error("No response from assistant.");
      }
    } catch (error) {
      console.error("Error:", error);
      setMessages((prev) => [
        ...prev,
        { role: "assistant", content: "Sorry, something went wrong!" },
      ]);
    } finally {
      setIsLoading(false);
      setInput("");
    }
  };
  useEffect(() => {
    const prompt = adminstate?.prompts.find((p) => p?.id === id);
    if (prompt?.description) {
      console.log({ des: prompt?.description });

      // handleSendMessage(
      //   prompt?.description
      // );
    }
  }, []);

  console.log(threads);

  return (
    <div className="bg-white rounded-lg shadow-lg w-full max-w-7xl flex items-stretch h-full mx-auto relative overflow-hidden">
      <div
        className={clsx(
          "flex flex-col border-r overflow-hidden transition-all ease-in-out duration-500 lg:static absolute left-0 top-0 z-[4] h-full bg-white",
          collapsed ? "w-0" : "lg:w-[30%] w-[250px]"
        )}
      >
        {/* Header */}
        <div
          className={clsx(
            "flex justify-between items-center p-4 border-b  transition-all duration-500",
            collapsed ? "opacity-0" : "opacity-100"
          )}
        >
          <h2 className="text-xl font-semibold whitespace-nowrap">All Chats</h2>
          <div className="flex justify-between gap-2">
            <button
              onClick={() => setCollapsed(!collapsed)}
              className="text-gray-500 hover:text-gray-700"
            >
              <Menu className="w-6 h-6" />
            </button>
            <button
              disabled={isLoading}
              onClick={() => {
                if (window?.innerWidth < 1024) {
                  setCollapsed(true);
                }
                setThreadId(null);
                localStorage.removeItem("threadId");
                setInput("");
                setMessages([]);
              }}
            >
              <Plus
                className={clsx(
                  "w-6 h-6",
                  isLoading ? "text-gray-500/50" : "text-blue-600"
                )}
              />
            </button>
          </div>
        </div>

        {/* All Chats */}
        <div
          className={clsx(
            "flex-1 p-2 prompt-gallery-scrollbar overflow-y-auto max-h-[600px]",
            collapsed ? "overflow-hidden hidden" : "block"
          )}
        >
          {threadLoading ? (
            <ul>
              {Array.from({ length: 5 }).map((_, index) => (
                <li key={index} className="my-2 h-10">
                  <div className="rounded-lg w-full h-full">
                    <Skeleton
                      title={false}
                      paragraph={{
                        rows: 1,
                        className:
                          "!h-full !w-full [&>li]:!w-full [&>li]:!h-full",
                      }}
                      active={threadLoading}
                      className="mb-2 h-full"
                    />
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            threads
              ?.sort(
                (a, b) =>
                  new Date(b?.createdAt).getTime() -
                  new Date(a?.createdAt).getTime()
              )
              ?.map((thread) => (
                <button
                  disabled={isLoading}
                  key={thread?.threadId}
                  onClick={() => {
                    setSelectedThreadId(thread?.threadId);
                    setInput("");
                    localStorage.setItem("threadId", thread?.threadId);
                    if (window?.innerWidth < 1024) {
                      setCollapsed(true);
                    }
                  }}
                  className={`my-2 p-3 text-left rounded-lg  w-full cursor-pointer  ${
                    thread?.threadId === threadId
                      ? "bg-gray-100"
                      : "hover:bg-gray-50 "
                  }
                  ${
                    isLoading && thread?.threadId !== threadId
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }
                  `}
                >
                  <p
                    className={clsx(
                      "text-sm truncate max-w-[250px]",
                      !collapsed ? "whitespace-nowrap" : "whitespace-normal"
                    )}
                  >
                    {thread?.title || thread?.createdAt}
                  </p>
                </button>
              ))
          )}
        </div>
      </div>
      <div className="flex flex-col w-full">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <div className="flex items-center gap-2">
            <button
              onClick={() => setCollapsed(!collapsed)}
              className={clsx(
                "text-gray-500 hover:text-gray-700",
                !collapsed ? "opacity-0 lg:hidden" : "opacity-100 lg:block"
              )}
            >
              <Menu className="w-6 h-6" />
            </button>
            <h2 className="text-xl font-semibold">Chat</h2>
          </div>
          <button
            onClick={() => {
              localStorage.removeItem("threadId");
              navigate("/dashboard/playground");
            }}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        {/* Chat Messages */}
        <div
          id="chatWrapper"
          className="flex-1 p-4 overflow-y-auto max-h-[550px] prompt-gallery-scrollbar"
        >
          {chatsLoading ? (
            <div className="flex justify-center items-center h-full">
              <Loader2 className="w-10 h-10 inline-block text-primary animate-spin" />
            </div>
          ) : messages?.length > 0 ? (
            messages.map((msg, index) => (
              <div
                key={index}
                className={`my-3 text-sm flex flex-col 
                  ${msg.role === "user" ? "ml-auto order-3" : "mr-auto"}
                  ${
                    msg?.content?.split(" ").length > 20
                      ? "max-w-lg"
                      : "max-w-max"
                  }`}
              >
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                  }}
                  className={`font-sans border-0 break-words p-3 cursor-auto active:border-none focus:!border-0 rounded-3xl focus:ring-0 focus:!outline-none !outline-none ${
                    msg.role === "user"
                      ? "!bg-primary hover:bg-primary text-white rounded-tr-lg"
                      : "!bg-blue-50 hover:bg-blue-50 mr-auto text-blue-900 rounded-tl-lg"
                  }`}
                >
                  {msg?.content}
                </pre>
              </div>
            ))
          ) : (
            <div className="my-3 p-3 rounded-lg w-full bg-blue-50 text-blue-900 mr-auto">
              <p className="text-sm">Hello! How can I help you today?</p>
            </div>
          )}
          {isLoading && (
            <div className="my-3 mr-auto">
              <p className="text-sm px-3 py-2 bg-white w-[70px] rounded">
                <span className={styles?.loader}></span>
              </p>
            </div>
          )}
        </div>

        {/* Input Area (Fixed at Bottom) */}
        <div className="p-4">
          <div className="flex items-center relative">
            <textarea
              rows={5}
              disabled={isLoading}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
              placeholder="Type a message..."
              className="flex-1 p-2 pr-20 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 overflow-hidden"
            />
            <button
              onClick={() => handleSendMessage()}
              className="ml-2 p-2 bg-primary text-white rounded-lg hover:bg-blue-600 absolute right-2 bottom-2"
            >
              <Send className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPlayground;

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  updateProfile,
  sendEmailVerification,
  sendPasswordResetEmail,
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
  User as FirebaseUser,
  signInAnonymously,
} from "firebase/auth";
import { doc, getDoc, setDoc, serverTimestamp, addDoc, collection } from "firebase/firestore";
import { auth, db } from "./firebase/app";
import { User } from "./types";
import { CustomToast } from "@/utils/helpers";

export async function AnonymouslySignIn() {
  try {
    await signInAnonymously(auth);
  } catch (error) {
    console.error("Anonymous sign in error:", error);
  }
}

export async function signIn(email: string, password: string): Promise<User> {
  if (!email || !password) {
    throw new Error("Email and password are required");
  }

  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );

    // Send verification email if not verified
    if (!userCredential.user.emailVerified) {
      await sendEmailVerification(userCredential.user);
    }

    const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));

    if (!userDoc.exists()) {
      throw new Error("User data not found");
    }

    return {
      id: userDoc.id,
      ...userDoc.data(),
      emailVerified: userCredential.user.emailVerified,
    } as User;
  } catch (error: any) {
    console.error("Sign in error:", error);
    if (error.code === "auth/invalid-credential") {
      throw new Error("Invalid email or password");
    }
    if (error.code === "auth/network-request-failed") {
      throw new Error("Network error. Please check your connection.");
    }
    if (error.code === "auth/too-many-requests") {
      throw new Error("Too many failed attempts. Please try again later.");
    }
    throw new Error("Failed to sign in. Please try again.");
  }
}

export async function register(data: {
  email: string;
  password: string;
  name: string;
  phoneNumber:string
  optIn: boolean;
}){
  if (!data.email || !data.password || !data.name || !data.phoneNumber || !data.optIn) {
    throw new Error("All fields are required");
  }
  try {
    const signUpUserEndpoint = import.meta.env.VITE_FIREBASE_CLOUD_URL + "/signUp";
    const res = await fetch(signUpUserEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: data.email,
        password: data.password,
        name: data.name,
        phoneNumber: data.phoneNumber,
        optIn: data.optIn
      }),
    });
  
      if (res.status !== 200) {
        const errorText = await res.json();
        throw new Error(errorText.message);
        // removing mail commits
      }

    } catch (error:any) {
      throw new Error(error?.message); 
     }
}

export async function signOut(): Promise<void> {
  try {
    await firebaseSignOut(auth);
    // Sign in anonymously after signing out
    await AnonymouslySignIn();
  } catch (error) {
    console.error("Sign out error:", error);
    throw new Error("Failed to sign out. Please try again.");
  }
}

export async function resendVerificationEmail(): Promise<void> {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("No user is currently signed in");
  }

  try {
    await sendEmailVerification(user);
  } catch (error: any) {
    console.error("Error sending verification email:", error);
    throw new Error(
      "Failed to send verification email. Please try again later."
    );
  }
}

export async function resetPassword(email: string): Promise<void> {
  if (!email) {
    throw new Error("Email is required");
  }

  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error: any) {
    console.error("Error sending password reset email:", error);
    if (error.code === "auth/user-not-found") {
      throw new Error("No account found with this email address");
    }
    throw new Error(
      "Failed to send password reset email. Please try again later."
    );
  }
}

export async function verifyEmail(code: string): Promise<void> {
  try {
    console.log({code});
    await applyActionCode(auth, code);  
  } catch (error: any) {
    if (error.code === "auth/invalid-action-code") {
      console.error("Invalid code or expired.");
    } else {
      console.error("Unexpected error:", error);
    }
  }
}

export async function confirmPasswordChange(
  code: string,
  newPassword: string
): Promise<void> {
  try {
    // First verify the password reset code
    await verifyPasswordResetCode(auth, code);

    // Then confirm the password reset
    await confirmPasswordReset(auth, code, newPassword);
  } catch (error: any) {
    console.error("Error confirming password reset:", error);
    throw error
  }
}

import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
} from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "@/lib/firebase/app";
import { Prompt, User } from "@/lib/types";
import { Category } from "@/lib/categories";
import { useAuth } from "./AuthContext";
import * as JsSearch from "js-search";
import { useLocation } from "react-router-dom";

export interface Office {
  id: string;
  createdAt: string;
  name: string;
  address: string;
  workspaceId: string;
}

interface AdminState {
  offices: Office[];
  prompts: Prompt[];
  prompt: Prompt | null;
  categories: Category[];
  users: User[];
  loading: boolean;
  error: string | null;
  assosicatedAdmins: User[] | null;
  allSuperAdmins: User[],
  allAdmins: User[],
  allVendors: User[],
  allAgents: User[],
  owners: User[];
  suggestedUsers: User[];
  loadedSuggestion: boolean;
  currentPage: number;
  usersPerPage: number;
}

type AdminAction =
  | { type: "SET_LOADING"; payload: boolean }
  | { type: "SET_ERROR"; payload: string | null }
  | { type: "SET_OFFICES"; payload: any }
  | { type: "SET_CATEGORIES"; payload: any }
  | { type: "DELETE_CATEGORY"; payload: string }
  | { type: "UPDATE_CATEGORY"; payload: any }
  | { type: "SET_PROMPTS"; payload: any }
  | { type: "SET_PROMPT"; payload: any }
  | { type: "UPDATE_PROMPT"; payload: any }
  | { type: "DELETE_PROMPT"; payload: string }
  | { type: "SET_USERS"; payload: User[] }
  | { type: "ADD_USER"; payload: User }
  | { type: "UPDATE_USER"; payload: User }
  | { type: "ADD_OFFICE"; payload: any }
  | { type: "UPDATE_OFFICE"; payload: any }
  | { type: "DELETE_OFFICE"; payload: string }
  | { type: "SET_ASSOSIATED_ADMINS"; payload: User[] }
  | { type: "SET_OWNER"; payload: User[] }
  | { type: "SET_ALL_SUPER_ADMINS"; payload: User[] }
  | { type: "SET_ALL_ADMINS"; payload: User[] }
  | { type: "SET_ALL_VENDORS"; payload: User[] }
  | { type: "SET_ALL_AGENTS"; payload: User[] }
  | { type: "SEARCH_USER"; payload: { value: string } }
  | { type: "REMOVE_SEARCH_USER" }
  | { type: "RESET_STATE" }
  | { type: "UPDATE_SUPER_ADMIN"; payload: User }
  | { type: "DELETE_SUPER_ADMIN"; payload: User }
  | {  type: "SET_PAGE" ; payload: number }
  | { type: "NEXT_PAGE" }
  | { type: "PREV_PAGE" };

const initialState: AdminState = {
  offices: [],
  categories: [],
  prompts: [],
  prompt: null,
  users: [],
  loading: true,
  error: null,
  assosicatedAdmins: [],
  allSuperAdmins: [],
  allAdmins: [],
  allVendors: [],
  allAgents: [],
  owners:[],
  suggestedUsers: [],
  loadedSuggestion: false,
  currentPage: 1,
  usersPerPage: 52,
};

const AdminContext = createContext<{
  state: AdminState;
  dispatch: React.Dispatch<AdminAction>;
} | null>(null);

function adminReducer(state: AdminState, action: AdminAction): AdminState {
  const pathname = window.location.pathname;
  var search = new JsSearch.Search("id");
  search.addIndex("name");
  search.addIndex("email");
  search.addDocuments(
    pathname === "/dashboard/admin/admins"
      ? state?.allAdmins :
    pathname === "/dashboard/admin/agents" ?
      state.allAgents : 
      pathname === "/dashboard/admin/vendors" ?
      state.allVendors
      : state?.allSuperAdmins || []
  );
  switch (action.type) {
    case "SEARCH_USER":
      const suggestion = search.search(action?.payload?.value);
      return {
        ...state,
        suggestedUsers: suggestion as any,
        loadedSuggestion: true,
      };
    case "REMOVE_SEARCH_USER":
      return {
        ...state,
        suggestedUsers: [],
        loadedSuggestion: false,
      };
    case "SET_LOADING":
      return { ...state, loading: action.payload, error: null };

    case "SET_ERROR":
      return { ...state, error: action.payload, loading: false };

    case "SET_OFFICES":
      return { ...state, offices: action.payload, loading: false };

    case "SET_CATEGORIES":
      return { ...state, categories: action.payload, loading: false };
    case "SET_PROMPTS":
      return { ...state, prompts: action.payload, loading: false };
    case "SET_PROMPT":
      return { ...state, prompt: action.payload, loading: false };
    case "SET_ALL_SUPER_ADMINS": {
      return {
        ...state,
        allSuperAdmins: action.payload,
      };
    }
    case "SET_ALL_ADMINS": {
      return {
        ...state,
        allAdmins: action.payload,
      };
    }
    case "SET_ALL_VENDORS": {
      return {
        ...state,
        allVendors: action.payload,
      };
    }
    case "SET_ALL_AGENTS": {
      return {
        ...state,
        allAgents: action.payload,
      };
    }

    case "UPDATE_CATEGORY":
      const updatedCategory = action.payload;
      const categoryRef = doc(db, "categories", updatedCategory.id);
      (async () => {
        try {
          await updateDoc(categoryRef, {
            name: updatedCategory.name,
            description: updatedCategory.description,
            assistantId: updatedCategory.assistantId,
          });
          return {
            ...state,
            categories: state.categories.map((cat: any) =>
              cat.id === updatedCategory.id ? updatedCategory : cat
            ),
            loading: false,
          };
        } catch (error) {
          console.error("Error updating category in Firestore:", error);
          return {
            ...state,
            loading: false,
            error: "Failed to update category",
          };
        }
      })();
      return state;
    case "UPDATE_PROMPT":
      return {
        ...state,
        prompts: state.prompts.map((cat: any) =>
          cat.id === action?.payload?.id ? action?.payload : cat
        ),
        loading: false,
      };
    case "DELETE_CATEGORY":
      (async () => {
        try {
          await deleteDoc(doc(db, "categories", action.payload));
        } catch (error) {
          console.error("Error deleting category from Firebase:", error);
        }
      })();
      return {
        ...state,
        categories: state.categories.filter(
          (category: Category) => category.id !== action.payload
        ),
      };
    case "SET_USERS":
      return { ...state, users: action.payload, loading: false };

    case "ADD_USER":
      return { ...state, users: [...state.users, action.payload] };

    case "UPDATE_USER":
      return {
        ...state,
        users: state.users.map((user) => {
          return user.id === action.payload.id ? action.payload : user;
        }),
      };
    case "UPDATE_SUPER_ADMIN":
      return {
        ...state,
        allSuperAdmins: state.allSuperAdmins.map((user) => {
          return user.id === action.payload.id ? action.payload : user;
        }),
        suggestedUsers: state.loadedSuggestion
          ? state.suggestedUsers.map((user) =>
              user.id === action.payload.id ? action.payload : user
            )
          : [],
      };

    case "DELETE_SUPER_ADMIN":
      return {
        ...state,
        suggestedUsers: state?.loadedSuggestion
          ? state.suggestedUsers.filter((user) => user.id !== action.payload.id)
          : [],
      };

    case "ADD_OFFICE":
      return { ...state, offices: [...state.offices, action.payload] };

    case "UPDATE_OFFICE":
      return {
        ...state,
        offices: state.offices.map((office: any) =>
          office.id === action.payload.id ? action.payload : office
        ),
      };

    case "DELETE_OFFICE":
      return {
        ...state,
        offices: state.offices.filter(
          (office: any) => office.id !== action.payload
        ),
      };

    case "SET_ASSOSIATED_ADMINS": {
      return {
        ...state,
        assosicatedAdmins: action.payload,
      };
    }
    case "SET_OWNER": {
      return {
        ...state,
        owners: action.payload,
      };
    }
    case "RESET_STATE": {
      return {
        ...initialState,
      };
    }
    case "NEXT_PAGE":
      return {
        ...state,
        currentPage: state.currentPage + 1,
      };
    case "PREV_PAGE":
      return {
        ...state,
        currentPage: state.currentPage > 1 ? state.currentPage - 1 : 1,
      };
      case "SET_PAGE": // New Case for Pagination
      return {
        ...state,
        currentPage: action.payload,
      };
    default:
      return state;
  }
}

export function AdminProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(adminReducer, initialState);
  const { state: authState } = useAuth();
  useEffect(() => {
    const loadAdminData = async () => {
      try {
        dispatch({ type: "SET_LOADING", payload: true });
        let officeQuery: any;
        let categoryQuery: any;
        let promptQuery: any;
        if (authState?.readOnlyUser?.role === "SUPER_ADMIN") {
          promptQuery = query(
            collection(db, "users", authState.readOnlyUser?.id, "prompts")
          );
        } else if (authState?.readOnlyUser?.role === "AGENT") {
          promptQuery = query(
            collection(db, "users", authState.readOnlyUser?.workspaceId as any, "prompts")
          );
        }
         else {
          if (authState?.user?.role === "SUPER_ADMIN") {
            promptQuery = query(
              collection(db, "users", authState.user?.id, "prompts")
            );
          } else {
            promptQuery = query(
              collection(
                db,
                "users",
                authState.user?.workspaceId as any,
                "prompts"
              ),
              where("workspaceId", "==", authState?.user?.workspaceId)
            );
          }
        }
        const unSubPrompts = onSnapshot(
          promptQuery,
          (snapshot: any) => {
            const prompts = snapshot.docs.map((doc: any) => ({
              id: doc.id,
              ...doc.data(),
            })) as any;
            dispatch({ type: "SET_PROMPTS", payload: prompts });
          },
          (error) => {
            console.error("Error loading prompts:", error);
            if (error.code !== "permission-denied") {
              dispatch({
                type: "SET_ERROR",
                payload: "Failed to load prompts",
              });
            }
          }
        );
        if (authState?.readOnlyUser?.role === "SUPER_ADMIN") {
          officeQuery = query(
            collection(db, "offices"),
            where("workspaceId", "==", authState.readOnlyUser?.workspaceId)
          );
        } else if (authState?.readOnlyUser?.role === "ADMIN") {
          officeQuery = query(
            collection(db, "offices"),
            where("id", "in", authState?.readOnlyUser?.officeIds || "")
          );
        }
         else {
          if (authState?.user?.role === "SUPER_ADMIN") {
            officeQuery = query(
              collection(db, "offices"),
              where("workspaceId", "==", authState.user?.workspaceId)
            );
          } else {
            officeQuery = query(
              collection(db, "offices"),
              where("id", "in", authState?.user?.officeIds || "")
            );
          }
        }
        const unsubOffices = onSnapshot(
          officeQuery,
          (snapshot: any) => {
            const offices = snapshot.docs.map((doc: any) => ({
              id: doc.id,
              ...doc.data(),
            })) as any;
            dispatch({ type: "SET_OFFICES", payload: offices });
          },
          (error) => {
            console.error("Error loading offices:", error);
            if (error.code !== "permission-denied") {
              dispatch({
                type: "SET_ERROR",
                payload: "Failed to load offices",
              });
            }
          }
        );
        if (authState?.readOnlyUser?.role === 'SUPER_ADMIN') {
          categoryQuery = query(
            collection(db, "categories"),
            where("workspaceId", "==", authState.readOnlyUser?.workspaceId)
          );
        } else {
          categoryQuery = query(
            collection(db, "categories"),
            where("workspaceId", "==", authState.user?.workspaceId)
          );
        }
        const unsubCategories = onSnapshot(
          categoryQuery,
          (snapshot: any) => {
            const categories = snapshot.docs.map((doc: any) => ({
              id: doc.id,
              ...doc.data(),
              default: doc?.data()?.default || false,
            })) as Category[];

            dispatch({ type: "SET_CATEGORIES", payload: categories });
            dispatch({ type: "SET_LOADING", payload: false });
          },
          (error) => {
            console.error("Error loading categories:", error);
            if (error.code !== "permission-denied") {
              dispatch({
                type: "SET_ERROR",
                payload: "Failed to load categories",
              });
            }
          }
        );

        return () => {
          unsubOffices();
          unsubCategories();
          unSubPrompts();
        };
      } catch (error) {
        console.error("Error loading admin data:", error);
        dispatch({ type: "SET_ERROR", payload: "Failed to load admin data" });
      }
    };

    if (authState?.user?.id) {
      loadAdminData();
    }
  }, [authState?.user, authState?.readOnlyUser]);

  useEffect(() => {
    const loadData = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        let queryCondition: any;
        if (authState?.readOnlyUser?.role === "SUPER_ADMIN") {
          queryCondition = query(
            collection(db, "users"),
            where("workspaceId", "==", authState.readOnlyUser?.workspaceId),
            where("id", "!=", authState?.readOnlyUser?.id)
          );
        } else if (authState?.readOnlyUser?.role === 'ADMIN') {
          queryCondition = query(
            collection(db, "users"),
            where(
              "officeIds",
              "array-contains-any",
              authState?.readOnlyUser?.officeIds
            ),
            where("role", "==", "AGENT"),
            where("id", "!=", authState?.readOnlyUser?.id)
          );
        }
        else {
          if (authState?.user?.role === "SUPER_ADMIN") {
            queryCondition = query(
              collection(db, "users"),
              where("workspaceId", "==", authState.user?.workspaceId),
              where("id", "!=", authState?.user?.id)
            );
          } else {
            queryCondition = query(
              collection(db, "users"),
              where(
                "officeIds",
                "array-contains-any",
                authState?.user?.officeIds
              ),
              where("role", "==", "AGENT"),
              where("id", "!=", authState?.user?.id)
            );
          }
        }
        const unsubUsers = onSnapshot(
          queryCondition,
          (snapshot: any) => {
            const users = (snapshot.docs || []).map((doc: any) => {
              const userData = doc.data();

              return {
                id: doc.id,
                ...userData,
              };
            }) as User[];
            dispatch({ type: "SET_USERS", payload: users });
          },
          (error) => {
            console.error("Error loading users:", error);
            if (error.code !== "permission-denied") {
              dispatch({ type: "SET_ERROR", payload: "Failed to load users" });
            }
            dispatch({ type: "SET_LOADING", payload: false });
          }
        );

        return () => {
          unsubUsers();
        };
      } catch (error) {
        console.error("Error loading admin data:", error);
        dispatch({ type: "SET_ERROR", payload: "Failed to load admin data" });
      } finally {
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };

    const loadAllSuperAdmins = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const unsubUsers = onSnapshot(
          query(
            collection(db, "users"),
            where("role", "==", "SUPER_ADMIN"),
            where("id", "!=", authState?.user?.id)
          ),
          (snapshot: any) => {
            const users = (snapshot.docs || []).map((doc: any) => {
              const userData = doc.data();

              return {
                id: doc.id,
                ...userData,
              };
            }) as User[];
            dispatch({ type: "SET_ALL_SUPER_ADMINS", payload: users });
            dispatch({ type: "SET_LOADING", payload: false });
          },
          (error) => {
            console.error("Error loading users:", error);
            if (error.code !== "permission-denied") {
              dispatch({ type: "SET_ERROR", payload: "Failed to load users" });
            }
            dispatch({ type: "SET_LOADING", payload: false });
          }
        );

        return () => {
          unsubUsers();
        };
      } catch (error) {
        console.error("Error loading admin data:", error);
        dispatch({ type: "SET_ERROR", payload: "Failed to load admin data" });
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };
    const loadAllAdmins = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const unsubUsers = onSnapshot(
          query(
            collection(db, "users"),
            where("role", "==", "ADMIN"),
            where("id", "!=", authState?.user?.id)
          ),
          (snapshot: any) => {
            const users = (snapshot.docs || []).map((doc: any) => {
              const userData = doc.data();

              return {
                id: doc.id,
                ...userData,
              };
            }) as User[];
            dispatch({ type: "SET_ALL_ADMINS", payload: users });
            dispatch({ type: "SET_LOADING", payload: false });
          },
          (error) => {
            console.error("Error loading users:", error);
            if (error.code !== "permission-denied") {
              dispatch({ type: "SET_ERROR", payload: "Failed to load users" });
            }
            dispatch({ type: "SET_LOADING", payload: false });
          }
        );

        return () => {
          unsubUsers();
        };
      } catch (error) {
        console.error("Error loading admin data:", error);
        dispatch({ type: "SET_ERROR", payload: "Failed to load admin data" });
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };
    const loadAllVendors = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const unsubUsers = onSnapshot(
          query(
            collection(db, "users"),
            where("role", "==", "VENDOR"),
            where("id", "!=", authState?.user?.id)
          ),
          (snapshot: any) => {
            const users = (snapshot.docs || []).map((doc: any) => {
              const userData = doc.data();

              return {
                id: doc.id,
                ...userData,
              };
            }) as User[];
            dispatch({ type: "SET_ALL_VENDORS", payload: users });
            dispatch({ type: "SET_LOADING", payload: false });
          },
          (error) => {
            console.error("Error loading users:", error);
            if (error.code !== "permission-denied") {
              dispatch({ type: "SET_ERROR", payload: "Failed to load users" });
            }
            dispatch({ type: "SET_LOADING", payload: false });
          }
        );

        return () => {
          unsubUsers();
        };
      } catch (error) {
        console.error("Error loading admin data:", error);
        dispatch({ type: "SET_ERROR", payload: "Failed to load admin data" });
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };
    const loadAllAgents = async () => {
      dispatch({ type: "SET_LOADING", payload: true });
      try {
        const unsubUsers = onSnapshot(
          query(
            collection(db, "users"),
            where("role", "==", "AGENT"),
            where("id", "!=", authState?.user?.id)
          ),
          (snapshot: any) => {
            const users = (snapshot.docs || []).map((doc: any) => {
              const userData = doc.data();

              return {
                id: doc.id,
                ...userData,
              };
            }) as User[];
            dispatch({ type: "SET_ALL_AGENTS", payload: users });
            dispatch({ type: "SET_LOADING", payload: false });
          },
          (error) => {
            console.error("Error loading users:", error);
            if (error.code !== "permission-denied") {
              dispatch({ type: "SET_ERROR", payload: "Failed to load users" });
            }
            dispatch({ type: "SET_LOADING", payload: false });
          }
        );

        return () => {
          unsubUsers();
        };
      } catch (error) {
        console.error("Error loading admin data:", error);
        dispatch({ type: "SET_ERROR", payload: "Failed to load admin data" });
        dispatch({ type: "SET_LOADING", payload: false });
      }
    };
    const loadAdmins = () => {
      let queryCondition: any;
      if (authState?.readOnlyUser !== null) {
        queryCondition = query(
          collection(db, "users"),
          where("role", "!=", "AGENT"),
          where("workspaceId", "==", authState?.readOnlyUser?.workspaceId)
        );
      } else {
        queryCondition = query(
          collection(db, "users"),
          where("role", "!=", "AGENT"),
          where("workspaceId", "==", authState.user?.workspaceId)
        );
      }
      const unsubAdmins = onSnapshot(
        queryCondition,
        async (snapshot: any) => {
          const admins = (snapshot.docs || []).map((doc: any) => ({
            id: doc.id,
            ...doc.data(),
          })) as User[];
          const q1 = query(
            collection(db, "users"),
            where("email", "in", [
              "aqilusman49@gmail.com",
              "tyler@joinvictory.com",
            ])
          );
          const querySnapshot1 = await getDocs(q1);
          const data = querySnapshot1.docs.map((doc) => doc.data()) as User[];
          dispatch({ type: "SET_ASSOSIATED_ADMINS", payload: admins });
          dispatch({ type: "SET_OWNER", payload: data });
        },
        (error) => {
          console.error("Error loading admins:", error);
          if (error.code !== "permission-denied") {
            dispatch({ type: "SET_ERROR", payload: "Failed to load admins" });
          }
        }
      );

      return () => {
        unsubAdmins();
      };
    };

    if (authState?.user?.id) {
      loadAdmins();
    }
    if (authState?.user?.id && authState?.user?.additionalRole === "MASTER") {
      loadAllSuperAdmins();
      loadAllAdmins()
      loadAllAgents()
      loadAllVendors()
    }

    if (authState?.user?.id) {
      loadData();
    }
  }, [
    authState?.user,
    state?.offices,
    state?.categories,
    state?.prompts,
    authState?.readOnlyUser,
  ]);

  useEffect(() => {
    dispatch({ type: "REMOVE_SEARCH_USER" });
    dispatch({ type: "RESET_STATE" });
  }, [authState?.readOnlyUser]);

  return (
    <AdminContext.Provider value={{ state, dispatch }}>
      {children}
    </AdminContext.Provider>
  );
}

export function useAdmin() {
  const context = useContext(AdminContext);
  if (!context) {
    throw new Error("useAdmin must be used within an AdminProvider");
  }
  return context;
}

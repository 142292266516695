import { useBulkUpload } from "@/context/BulkUploadContext";
import Button from "@/components/ui/Button";
import clsx from "clsx";
import BulkUploadStep1 from "./BulkUploadSteps/BulkUploadStep1";
import BulkUploadStep2 from "./BulkUploadSteps/BulkUploadStep2";
import BulkUploadStep3 from "./BulkUploadSteps/BulkUploadStep3";
import { useAuth } from "@/context/AuthContext";
import { createBulkUsers } from "@/api/user";
import { CustomToast } from "@/utils/helpers";
import BulkUploadReport from "./BulkUploadSteps/BulkUploadReport";
import { useAdmin } from "@/context/AdminContext";
import { MessageSquareWarning } from "lucide-react";
import { useNavigate } from "react-router-dom";
import ButtonLoader from "./ui/ButtonLoader";

export default function BulkUpload() {
  const { dispatch, state } = useBulkUpload();
  const { state: authState, getToken } = useAuth();
  const { state: adminState } = useAdmin();

  const onPreview = () => {
    dispatch({ type: "SET_STEP", payload: state?.step + 1 });
  };
  const onBack = () => {
    dispatch({
      type: "SET_STEP",
      payload: state?.step > 1 ? state?.step - 1 : 1,
    });
  };

  const isButtonDisabled = () => {
    const { data } = state;

    for (const item of data) {
      console.log({ item });
      const { name, email, officeIds, categoryIds, role, phoneNumber } = item;

      if (!name || !email?.trim() || !role) {
        return true;
      }

      const phoneNumberRegex = /^\+?[1-9]\d{1,14}$/;

      if (!phoneNumberRegex.test(phoneNumber)) {
        return true;
      }

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email?.trim())) {
        return true;
      }

      if (role === "AGENT") {
        if (categoryIds && categoryIds.length > 0) {
          return true;
        }
        if (!officeIds || officeIds.length === 0) {
          return true;
        }
      }

      if (role === "ADMIN") {
        if (
          !officeIds ||
          officeIds.length === 0 ||
          !categoryIds ||
          categoryIds.length === 0
        ) {
          return true;
        }
      }
      if (role === "VENDOR") {
        if (
          !officeIds ||
          officeIds.length === 0 ||
          !categoryIds ||
          categoryIds.length === 0
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      e.preventDefault();
      dispatch({
        type: "SET_LOADING",
        payload: true,
      });
      const usersToCreate = state?.data?.map((node) => {
        return {
          email: node?.email?.trim(),
          name: node?.name,
          role: node?.role,
          officeIds: node?.officeIds || [],
          categoryIds: node?.categoryIds || [],
          workspaceId:
            authState?.readOnlyUser !== null
              ? authState?.readOnlyUser?.workspaceId
              : authState?.user?.workspaceId,
          phoneNumber: node?.phoneNumber,
        };
      });
      console.log({ usersToCreate });

      const token = (await getToken()) as string;
      console.log({ token });

      const res: any = await createBulkUsers({
        token,
        body: {
          users: usersToCreate,
        },
      });

      if (res.status !== 200) {
        const errorText = await res.json();
        throw new Error(errorText.message);
      }
      const resJson = await res.json();
      console.log({ resJson });

      const { successfulUsers } = resJson || {};

      dispatch({
        type: "SET_REPORT",
        payload: { report: successfulUsers, showReport: true },
      });
    } catch (error: any) {
      CustomToast("error", error?.message);
    } finally {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    }
  };

  const navigate = useNavigate();

  return (
    <>
      {adminState?.offices?.length > 0 ? (
        <div className="mx-auto max-w-7xl flex flex-col gap-6">
          {state?.showReport ? (
            <BulkUploadReport />
          ) : (
            <>
              {state?.step === 1 && <BulkUploadStep1 />}
              {state?.step === 2 && <BulkUploadStep2 />}
              {state?.step === 3 && <BulkUploadStep3 />}
              <div
                className={clsx(
                  state?.step > 1 && state?.step !== 2
                    ? "justify-between"
                    : state?.step === 2
                    ? "justify-start"
                    : "justify-end",
                  "flex w-full gap-10"
                )}
              >
                {state?.step > 1 && (
                  <Button
                    onClick={onBack}
                    variant="outline"
                    className=""
                  >
                    Back
                  </Button>
                )}
                {state?.step < 3 ? (
                  <Button
                    onClick={onPreview}
                    disabled={
                      state?.step === 1
                        ? state?.fileName === "" || state?.data.length === 0
                        : state?.step === 2
                        ? isButtonDisabled()
                        : false
                    }
                    type="submit"
                    className="sm:ml-3"
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    disabled={state?.loading}
                    onClick={onSubmit}
                    className="w-full sm:ml-3 sm:w-auto"
                  >
                    Submit
                    {state?.loading && (
                      <ButtonLoader />
                    )}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="mx-auto max-w-7xl">
          <h1 className="text-2xl font-semibold text-gray-900">
            Bulk User Creation
          </h1>
          <div className="rounded-lg bg-white p-8 text-center mt-10">
            <MessageSquareWarning className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
            You haven't created an office yet. Please create one to add users.
            </h3>
            <Button
              onClick={() => navigate("/dashboard/admin/offices")}
              className="mt-4"
            >
              Create Office
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

import PromptCard from "@/components/PromptCard";
import { useAdmin } from "@/context/AdminContext";
import { FolderCog } from "lucide-react";

export default function PromptGallery() {
  const { state } = useAdmin();
  const prompts =
    state?.prompts.filter((prompt: any) => !prompt?.disabled) || [];

  return (
    <div className="mx-auto max-w-7xl h-full flex flex-col">
      <h1 className="text-2xl font-semibold text-gray-900">Playground</h1>
      {prompts?.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10">
          {prompts?.map((prompt) => (
            <PromptCard key={prompt?.id} {...prompt} />
          ))}
        </div>
      ) : (
        <div className="">
          <div className="rounded-lg bg-white p-8 text-center mt-10">
            <FolderCog className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No prompts
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              No prompts are available at the moment. Please check back later.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@/context/AuthContext';
import UserDashboard from './dashboards/UserDashboard';
import AdminDashboard from './dashboards/AdminDashboard';

export default function Dashboard() {
  const navigate = useNavigate();
  const { state } = useAuth();
  const location = useLocation(); 
const user = state?.readOnlyUser !== null ? state?.readOnlyUser : state?.user


  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }
  }, [user, navigate]);

  if (!user) return null;


  useEffect(() => {
    const isMobileDevice = /iPhone|Android|iPad|iPod/i.test(navigator.userAgent);
    if (
      location.pathname === "/dashboard" &&
      !sessionStorage.getItem("reloaded") &&
      isMobileDevice
    ) {
      sessionStorage.setItem("reloaded", "true");
      window.location.reload();
    }
  }, [location]);

  useEffect(() => {
    if (state?.readOnlyUser !== null && location.pathname === "/dashboard") {
      window.history.pushState(null, "", window.location.href);
      
      window.onpopstate = () => {
        window.history.pushState(null, "", window.location.href);
      };
    }

    return () => {
      window.onpopstate = null;
    };
  }, [location.pathname]);
  
  
  
  // Strict role-based dashboard rendering
  switch (user.role) {
    case 'AGENT':
      return <UserDashboard />;
    case 'ADMIN':
    case 'VENDOR':
    case 'SUPER_ADMIN':
      return <AdminDashboard />;
    default:
      // Log unexpected role and redirect to login
      console.error('Invalid user role:', user.role);
      navigate('/login');
      return null;
  }
}
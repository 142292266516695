import { Action } from "@/components/SuperAdminsTable";
import { User } from "@/lib/types";

const createUserEndpoint =
  import.meta.env.VITE_FIREBASE_CLOUD_URL + "/createUser";
const deleteUserEndpoint =
  import.meta.env.VITE_FIREBASE_CLOUD_URL + "/deleteUser";
const updateUserEndpoint =
  import.meta.env.VITE_FIREBASE_CLOUD_URL + "/updateUser";
const deleteSuperAdminEndpoint =
  import.meta.env.VITE_FIREBASE_CLOUD_URL + "/deleteSuperAdmin";

const createBulkUserEndpoint =
  import.meta.env.VITE_FIREBASE_CLOUD_URL + "/createBulkUsers";

export const createUser = async ({
  token,
  body,
}: {
  token: string;
  body: Record<string, any>;
}) => {
  return await fetch(createUserEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
};

export const deleteUser = async ({ id, token }: any) => {
  try {
    const response = await fetch(deleteUserEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ id }),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.log({ errorResponse });

      throw new Error(errorResponse.message || "Something went wrong");
    }

    const result = await response.json();
    console.log("Response from Firebase Function:", result);
    return result;
  } catch (error) {
    console.log({ error });
  }
};
export const updateUser = async ({ body, token }: any) => {
  return await fetch(updateUserEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
};

export const deleteSuperAdmin = async ({
  user,
  token,
  action,
}: {
  token: string;
  user?: User;
  action: Action;
}) => {
  const response = await fetch(deleteSuperAdminEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ action, user }),
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    console.log({ errorResponse });

    throw new Error(errorResponse.message || "Something went wrong");
  }

  const result = await response.json();
  console.log("Response from Firebase Function:", result);
  return result;
};

export const createBulkUsers = async ({
  token,
  body,
}: {
  token: string;
  body: Record<string, any>;
}) => {
  return await fetch(createBulkUserEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
};

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import SuperAdminView from "@/components/SuperAdminsView";
import { useAdmin } from "@/context/AdminContext";

const VendorWorkspaces = () => {
  const location = useLocation();
  const { state, dispatch } = useAdmin();

  // Prevent rendering hooks conditionally by refactoring useEffect
  useEffect(() => {
    const scrollToId = location?.state?.scrollToId;
    if (scrollToId) {
      const elem = document.getElementById(scrollToId);
      if (elem) {
        setTimeout(() => {
          elem.scrollIntoView({
            behavior: "smooth",
          });
        }, 100);
      }
    }
  }, [location.state?.scrollToId]);

  return (
    <SuperAdminView
      users={state.allVendors}
      adminState={state}
      dispatch={dispatch}
      heading="All Vendors"
      role="Vendor"
    />
  );
};

export default VendorWorkspaces;

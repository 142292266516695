import { useEffect, useState } from "react";
import { useAdmin } from "@/context/AdminContext";
// import { Office } from '@/lib/types';
import Button from "@/components/ui/Button";
import Modal from "@/components/ui/Modal";
import { Building, Pencil, Trash } from "lucide-react";
import { generateId } from "@/lib/utils";
import { useAuth } from "@/context/AuthContext";
import { deleteDoc, doc, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { db } from "@/lib/firebase";
import { Select, Space } from "antd";
import ButtonLoader from "@/components/ui/ButtonLoader";
import { insertNotification } from "@/lib/firebase/notification";

export default function Offices() {
  const { state, dispatch } = useAdmin();
  const { state: authState } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState({
    modal: false,
    assignOfficeModal: false,
  });
  const [editingOffice, setEditingOffice] = useState<any | null>(null);
  const [OfficeId, setOfficeId] = useState(null);
  const [selectedOfficeId, setSelectedOfficeId] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
  });
  const [disabled, setDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Sort offices alphabetically by name
  const sortedOffices = state?.offices?.sort((a: any, b: any) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );

  const getOfficeName = (officeId: string): string => {
    const office = state?.offices?.find(
      (office: Record<string, any>) => office?.id === officeId
    );
    return office?.name || "";
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const office = {
      workspaceId:
        authState.user !== null && authState?.readOnlyUser !== null
          ? authState.readOnlyUser?.workspaceId
          : authState?.user?.id,
      id: editingOffice?.id || generateId(),
      name: formData.name,
      address: formData.address,
      createdAt: editingOffice?.createdAt || new Date().toISOString(),
    };
    setIsSubmitting(true);
    try {
      if (!editingOffice) {
        await setDoc(doc(db, "offices", office.id), office);
      } else {
        const officeRef = doc(db, "offices", editingOffice.id);
        await updateDoc(officeRef, office);
      }

      dispatch({
        type: editingOffice && "UPDATE_OFFICE",
        payload: office,
      });

      setIsSubmitting(false);
      setIsModalOpen((prev) => ({ ...prev, modal: false }));
      setFormData({ name: "", address: "" });
    } catch (error) {
      console.error("Error saving office:", error);
    }
  };

  const handleEdit = (office: any) => {
    setEditingOffice(office);
    setFormData({
      name: office.name,
      address: office.address,
    });
    setIsModalOpen((prev) => ({ ...prev, modal: true }));
  };
  const handleDelete = async (id: string) => {
    const userHasOffice = state?.users?.filter((user) =>
      user.officeIds?.includes(id)
    );
    const officeToDelete = state.users.some((user) => user.officeIds?.includes(id) && user.officeIds?.length === 1);
    if (officeToDelete) {
      setIsModalOpen((prev) => ({ ...prev, assignOfficeModal: true }));
    } else {
      if (confirm("Are you sure you want to delete this office?")) {
        try {
          await deleteDoc(doc(db, "offices", id));
          dispatch({
            type: "DELETE_OFFICE",
            payload: id,
          });
          const batch = writeBatch(db);
          userHasOffice.forEach((user) => {
            const userRef = doc(db, "users", user.id);
            const updatedOfficeIds = user.officeIds?.filter(
              (existingOfficeId: string) => existingOfficeId !== id
            );
            batch.update(userRef, {
              officeIds: updatedOfficeIds,
            });
          })
          await batch.commit();
          let notificationPromises = [];
          for (const user of userHasOffice) {
            notificationPromises.push(
              insertNotification({
                userId: user.id,
                title: "Office Deleted",
                message: `You've been removed from the office: ${getOfficeName(id)}.`,
                workspaceId: user?.workspaceId ?? "",
                key: "office",
              })
            );
          }
          await Promise.all(notificationPromises);
          console.log(`Office with ID: ${id} has been deleted successfully.`);
        } catch (error) {
          console.error(`Error deleting office with ID: ${id}`, error);
          dispatch({
            type: "SET_ERROR",
            payload: "Failed to delete office",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (
      editingOffice?.address !== formData.address ||
      editingOffice?.name !== formData.name
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formData, editingOffice]);

  const handleAssignOffice = async (e: React.FormEvent) => {
    e.preventDefault();
    const usersWithOffice = state?.users?.filter((user) =>
      user.officeIds?.includes(selectedOfficeId || "")
    );
    try {
      setIsSubmitting(true);
      if (usersWithOffice.length > 0) {
        const batch = writeBatch(db);
        usersWithOffice.forEach((user) =>{
          const userRef = doc(db, "users", user.id);
          const updatedOfficeIds = user.officeIds?.filter(
            (existingOfficeId: string) =>
              existingOfficeId !== selectedOfficeId
          );
          batch.update(userRef, {
            officeIds: user.officeIds?.length === 1 ? [OfficeId] : updatedOfficeIds,
          });
        })
        await batch.commit();
        let notificationPromises = [];
        for (const user of usersWithOffice) {
          if (user.officeIds?.length === 1) {
            notificationPromises.push(
              insertNotification({
                userId: user.id,
                title: "Office Deleted",
                message: `Your office: "${getOfficeName(selectedOfficeId ?? "")}" has been replaced with office: "${getOfficeName(OfficeId ?? "")}" by ${authState.user?.name}.`,
                workspaceId: user?.workspaceId ?? "",
                key: "office",
              })
            );
          } else {
            notificationPromises.push(
              insertNotification({
                userId: user.id,
                title: "Office Deleted",
                message: `You've been removed from the office: "${getOfficeName(selectedOfficeId ?? "")}".`,
                workspaceId: user?.workspaceId ?? "",
                key: "office",
              })
            );
          }
        }
        await Promise.all(notificationPromises);
      } else {
        console.log("No users with this office");
      }
      await deleteDoc(doc(db, "offices", selectedOfficeId || ""));
      dispatch({
        type: "DELETE_OFFICE",
        payload: selectedOfficeId || "",
      })
      console.log(
        `Office with ID: ${selectedOfficeId} has been deleted successfully.`
      );
      setIsSubmitting(false);
      setOfficeId(null);
      setSelectedOfficeId(null);
      setIsModalOpen((prev) => ({ ...prev, assignOfficeModal: false }));
    } catch (error) {
      console.error(
        `Error deleting office with ID: ${selectedOfficeId}`,
        error
      );
      dispatch({
        type: "SET_ERROR",
        payload: "Failed to delete office",
      });
    }
  };

  return (
    <div className="mx-auto max-w-7xl">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-semibold text-gray-900">Offices</h1>
          <Button
            onClick={() => {
              setEditingOffice(null);
              setIsModalOpen((prev) => ({ ...prev, modal: true }));
            }}
          >
            Add Office
          </Button>
      </div>

      <div className="mt-8 px-4 lg:px-0">
        {sortedOffices.length === 0 ? (
          <div className="rounded-lg bg-white p-8 text-center">
            <Building className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-semibold text-gray-900">
              No offices
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Get started by creating a new office.
            </p>
          </div>
        ) : (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Name
                        </th>
                        <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Address
                        </th>
                        <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {sortedOffices.map((office: any) => (
                        <tr key={office.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {office.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {office.address}
                          </td>
                          {
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <div className="flex justify-end space-x-2">
                                  <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleEdit(office)}
                                  >
                                    <Pencil className="h-4 w-4" />
                                  </Button>
                                  <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => {
                                      handleDelete(office.id);
                                      setSelectedOfficeId(office?.id);
                                    }}
                                  >
                                    <Trash className="h-4 w-4" />
                                  </Button>
                                </div>
                            </td>
                          }
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <Modal
        isOpen={isModalOpen.modal}
        onClose={() => {
          setIsModalOpen((prev) => ({ ...prev, modal: false }));
          setFormData({ name: "", address: "" });
        }}
        title={editingOffice ? "Edit Office" : "Add Office"}
      >
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              className="mt-1 px-1 py-2 lock w-full rounded-md border-border-default shadow-sm focus:border-blue-antd focus-visible:border-blue-antd focus-visible:outline-0   border focus:ring-blue-antd sm:text-sm"
              required
            />
          </div>
          <div>
            <label
              htmlFor="address"
              className="block text-sm font-medium text-gray-700"
            >
              Address
            </label>
            <input
              type="text"
              id="address"
              value={formData.address}
              onChange={(e) =>
                setFormData({ ...formData, address: e.target.value })
              }
              className="mt-1 px-1 py-2 lock w-full rounded-md border-border-default shadow-sm focus:border-blue-antd focus-visible:border-blue-antd focus-visible:outline-0   border focus:ring-blue-antd sm:text-sm"
              required
            />
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <Button
              type="submit"
              className="w-full sm:ml-3 sm:w-auto"
              disabled={disabled || isSubmitting}
            >
              {editingOffice ? "Update" : "Create"}
            </Button>
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                setIsModalOpen((prev) => ({ ...prev, modal: false }));
                setFormData({ name: "", address: "" });
              }}
              className="mt-3 w-full sm:mt-0 sm:w-auto"
            >
              Cancel
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        isOpen={isModalOpen.assignOfficeModal}
        onClose={() => {
          setIsModalOpen((prev) => ({ ...prev, assignOfficeModal: false }));
          setOfficeId(null);
        }}
        title={"User Reassignment Needed for Office Deletion"}
      >
        <p>
          The office you are trying to delete is currently assigned to one or
          more users. To proceed with deletion, you must assign the user(s) to a
          different office.
        </p>
        <form className="mt-5" onSubmit={(e) => handleAssignOffice(e)}>
          <div>
            <Space className="w-full" direction="vertical">
              <Select
                disabled={false}
                allowClear
                className="w-full mt-1"
                placeholder="Please select office"
                value={OfficeId}
                onChange={(value) => {
                  setOfficeId(value);
                }}
                options={state?.offices
                  ?.filter((office: any) => office.id !== selectedOfficeId)
                  .map((office: any) => {
                    return {
                      label: office?.name,
                      value: office?.id,
                    };
                  })}
              />
            </Space>
          </div>

          <Button
            type="submit"
            className="w-full sm:w-auto mt-5"
            disabled={isSubmitting || !OfficeId}
          >
            Submit
            {isSubmitting && <ButtonLoader />}
          </Button>
        </form>
      </Modal>
    </div>
  );
}

import {  useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useAuth } from "@/context/AuthContext";
import Button from "@/components/ui/Button";
import { resendVerificationEmail } from "@/lib/auth";
import { ArrowUpRightIcon, LogOut } from "lucide-react";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { login, state, dispatch } = useAuth();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showResendVerification, setShowResendVerification] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const from = location?.state?.from?.pathname || "/dashboard";



  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setIsSubmitting(true);
    setShowResendVerification(false);

    try {
      if (!formData.email || !formData.password) {
        throw new Error("Please enter both email and password");
      }

      await login(formData.email, formData.password);
    
    } catch (err: any) {
      console.error("Login error:", err);
      setError(err.message || "Failed to sign in. Please try again.");

      // Show resend verification option if the error is about email verification
      if (err.message.includes("verify your email")) {
        setShowResendVerification(true);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResendVerification = async () => {
    setError(null);
    setSuccess(null);
    setIsSubmitting(true);

    try {
      await resendVerificationEmail();
      setSuccess(
        "Verification email has been resent. Please check your inbox."
      );
    } catch (err: any) {
      setError(err.message || "Failed to resend verification email.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResetPassword = async () => {
    setError('')
    dispatch({type:"SET_ERROR", payload:''})
    navigate("/reset-password", { state: { email: formData?.email } });
  };

  return (
    <div className="bg-[#0A0B1A] min-h-screen flex flex-col pt-6 pb-6 px-4 sm:px-6 md:pt-6 lg:px-8 ">
      <Link to="/" className="text-white w-fit ml-auto">
        <Button
            variant="outline"
            size="sm"
            onClick={() => null}
            className="text-white"
          >
            <LogOut className="h-4 w-4" />
          </Button>
      </Link>
      <div className="min-h-full flex items-center justify-center grow mt-2">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h1 className="text-center text-4xl font-bold tracking-tight">
              <span className="bg-gradient-to-r from-[#4169E1] to-[#9370DB] text-transparent bg-clip-text">
                Welcome Back
              </span>
            </h1>
            <h2 className="mt-6 text-center text-2xl font-bold text-white">
              Sign in to your account
            </h2>
          </div>

          <form className="mt-8 space-y-6" onSubmit={handleLogin}>
            {(error || state?.error) && (
              <div className="rounded-md bg-red-50 p-4">
                <p className="text-sm text-red-700">{error || state?.error}</p>
              </div>
            )}

            {success && (
              <div className="rounded-md bg-green-50 p-4">
                <p className="text-sm text-green-700">{success}</p>
              </div>
            )}

            <div className="rounded-md shadow-sm -space-y-px">
              <div className="mb-4">
                <label htmlFor="email" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  type="email"
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-700 bg-gray-800 text-white placeholder-gray-400 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  required
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                  className="appearance-none relative block w-full px-3 py-2 border border-gray-700 bg-gray-800 text-white placeholder-gray-400 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  required
                />
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <button
                  type="button"
                  onClick={handleResetPassword}
                  className="text-blue-400 hover:text-blue-300 flex items-center gap-1"
                >
                  Forgot your password
                  <ArrowUpRightIcon className="h-4 w-4" />
                </button>
              </div>
            </div>

            <div>
              <Button
                type="submit"
                className="w-full flex justify-center py-2 px-4"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Signing in..." : "Sign in"}
              </Button>
            </div>

            {showResendVerification && (
              <div className="text-center">
                <button
                  type="button"
                  onClick={handleResendVerification}
                  className="text-blue-400 hover:text-blue-300 text-sm"
                  disabled={isSubmitting}
                >
                  Resend verification email
                </button>
              </div>
            )}
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-700" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="bg-[#0A0B1A] px-2 text-gray-400">
                Interested in Victoria?
                </span>
              </div>
            </div>

            <div className="mt-6">
              <Button
                variant="outline"
                className="w-full text-white"
                onClick={() => navigate("/register")}
              >
                Join the Brokerage Waitlist
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

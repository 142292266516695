import { Pencil, Trash } from "lucide-react";
import Button from "@/components/ui/Button";
import { Category } from "@/lib/categories";

interface CategoryTableProps {
  categories: Category[];
  onEdit: (category: any) => void;
  onDelete: (id: string) => void;
  setSelectedCategoryId: (id: string) => void;
}

export default function CategoryTable({
  categories,
  onEdit,
  onDelete,
  setSelectedCategoryId
}: CategoryTableProps) {
  if (!Array.isArray(categories)) {
    console.error("Categories is not an array:", categories);
    return null;
  }

  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Name
                  </th>
                  <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Description
                  </th>
                  <th className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {categories?.map((category) => (
                  <tr key={`category-${category.id}`}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {category.name}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {category.description}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <div className="flex justify-end space-x-2">
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => onEdit(category)}
                            >
                              <Pencil className="h-4 w-4" />
                            </Button>
                          {!category?.default && (
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => {
                                onDelete(category.id);
                                setSelectedCategoryId(category.id);
                              }}
                            >
                              <Trash className="h-4 w-4" />
                            </Button>
                          )}
                        </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

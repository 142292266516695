import {
  collection,
  serverTimestamp,
  addDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { db } from "@/lib/firebase/app";

export const getNotificationRef = (id: string) =>
  collection(db, `users/${id}/notifications`);

export const insertNotification = async ({
  title,
  message,
  userId,
  requestId,
  workspaceId,
  key,
}: {
  title: string;
  message: string;
  userId: string;
  requestId?: string;
  workspaceId: string;
  key?: string;
}) => {
  console.log({
    title,
    message,
    userId,
    requestId,
    workspaceId,
  });

  if (!userId) return;

  try {
    const newNotification = {
      title,
      message,
      createdAt: serverTimestamp(),
      read: false,
      userId,
      requestId: requestId ? requestId : null,
      workspaceId,
      key: key,
    };
    pushNotification({
      userIds: [userId],
      notification: {
        title: title,
        body: message,
      },
    });
    const ref = getNotificationRef(userId);
    const docRef = doc(ref);
    await setDoc(docRef, { id: docRef?.id, ...newNotification });
    console.log("Notification inserted successfully");
  } catch (error) {
    console.log("Error inserting notification:", error);
  }
};

export const pushNotification = async ({
  userIds,
  notification,
}: {
  userIds: string[];
  notification: {
    title: string;
    body: string;
  };
}) => {
  console.log({
    userIds,
    notification,
  });
  const pushNotificationEndpoint =
    import.meta.env.VITE_FIREBASE_CLOUD_URL + "/pushNotification";
  const response = await fetch(pushNotificationEndpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userIds,
      notification,
    }),
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    console.log({ errorResponse });

    throw new Error(errorResponse.message || "Something went wrong");
  }

  const result = await response.json();
  console.log("Response from Firebase Function:", result);
  return result;
};

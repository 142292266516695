import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { updateRequest, useRequests } from "@/context/RequestsContext";
import { useAuth } from "@/context/AuthContext";
import { cn } from "@/lib/utils";
import { Filter, MessageSquare, SortDesc } from "lucide-react";
import Button from "@/components/ui/Button";
import RequestComments from "@/components/RequestComments";
import { Request, User } from "@/lib/types";
import { canProcessRequest } from "@/lib/permissions";
import { useAdmin } from "@/context/AdminContext";
import { Select, Space } from "antd";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "@/lib/firebase/app";
import { CustomToast } from "@/utils/helpers";
import Modal from "@/components/ui/Modal";
import { insertNotification } from "@/lib/firebase/notification";
import { useInView } from "react-intersection-observer";
import LoaderSpinner from "@/components/loader-spinner";
import TextArea from "antd/es/input/TextArea";
import { analyzeTranscript, getAssistantResponse } from "@/lib/openai";

const sendNotificationForStatusChange = async ({
  users,
  name,
  title,
  id,
  workspaceId,
  status,
}: {
  name: string;
  title: string;
  id: string;
  workspaceId: string;
  users: string[];
  status: string;
}) => {
  const allNotificationPromise: any = [];
  users.forEach((admin: any) => {
    allNotificationPromise.push(
      insertNotification({
        title: "Request Status Updated",
        message: `The status of the request: "${title}" has been changed to ${status} by ${name}.`,
        userId: admin,
        requestId: id,
        workspaceId: workspaceId,
        key: "request",
      })
    );
  });

  try {
    await Promise.all(allNotificationPromise);
    console.log("send Notification For Attachments ended");
  } catch (error) {
    console.error("Failed to send notifications:", error);
  }
};

export default function Requests() {
  const { state: requestState, dispatch, loadMoreRequests } = useRequests();
  const { state: authState } = useAuth();
  const { state: adminState } = useAdmin();
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const location = useLocation();
  const [updatingRequest, setUpdatingRequest] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<Request | null>(null);
  const searchParams = new URLSearchParams(location.search);

  console.log({authState});
  console.log({requestState});
  console.log({adminState});


  const navigate = useNavigate();

  const currentUser = authState?.readOnlyUser || authState?.user;

  let req = requestState?.loadedSuggestion
    ? requestState.suggestedRequests
    : requestState.requests;

  // if (currentUser?.role === "ADMIN") {
  //   const reqIds : string[] = []
  //   const allReq = [...requestState?.assignedRequest, ...requestState?.requests];
  //   req = allReq?.filter(request=>{
  //     if (request?.adminId && request?.adminId !== currentUser?.id) return false;
  //     if (reqIds?.includes(request.id)) return false;
  //     reqIds.push(request.id);
  //     return true
  //   })
  // }

  // Filter requests based on user role and permissions
  const filteredRequests = req?.filter((request) => {
    // First apply category and status filters
    if (selectedCategory && request.categoryId !== selectedCategory) return false;
    if (selectedStatus && request.status !== selectedStatus) return false;

    const user = authState.user;
    if (!user) return false;

    // Super admin can see all requests
    if (user.role === "SUPER_ADMIN") return true;

    // Admins can see requests for their offices and categories
    if (user.role === "ADMIN" || user.role === "VENDOR") {
      return true;
    }

    // Agents can only see their own requests
    if (user.role === "AGENT") {
      return request.agentId === user.id;
    }

    return false;
  });

  // Sort requests by creation date (newest first)
  const sortedRequests = filteredRequests

  const getCategoryNameById = (categoryId: string): string => {
    const category = adminState?.categories?.find(
      (category: Record<string, any>) => category?.id === categoryId
    );
    return category?.name || "";
  };

  // Handle scrolling to specific request when navigating from notification or dashboard
  useEffect(() => {
    const scrollToId = location.state?.scrollToId;
    console.log(scrollToId);

    const elem = document.getElementById(scrollToId);

    if (scrollToId && elem) {
      setTimeout(() => {
        elem.scrollIntoView({
          behavior: "smooth",
        });

        window.history.replaceState({}, document.title);
      }, 100);
    }
  }, [location.state?.scrollToId]);

  const requestId = searchParams.get("requestId");
  useEffect(() => {
    if (!requestId) return;
    const scrollToElement = () => {
      const elm = document.getElementById(requestId);
      if (elm) {
        elm.scrollIntoView({
          behavior: "smooth",
        });
        return true;
      }
      return false;
    };
    let retries = 0;
    const interval = setInterval(() => {
      if (scrollToElement() || retries > 30) {
        clearInterval(interval);
      }
      retries++;
    }, 100);

    return () => clearInterval(interval);
  }, [requestId]);

  const handleStatusUpdate = async (request: Request) => {
    const newStatus =
      request.status === "Pending" ? "In Progress" : "Completed";

    const updatedRequest: any = {
      ...request,
      status: newStatus,
    };

    const users = adminState.users
      .concat(adminState?.assosicatedAdmins || [])
      .filter((admin: User) => {
        const isSuperAdmin = admin?.role === "SUPER_ADMIN";
        const isAssociatedAdmin =
          request.adminId === null &&
          admin?.categoryIds.includes(request?.categoryId as string) &&
          admin?.officeIds.includes(request?.officeId as string);
        const isAssignedAdmin = request?.adminId === admin?.id;
        const isAgent = request.agentId === admin?.id;

        return (
          (isSuperAdmin || isAssociatedAdmin || isAssignedAdmin) &&
          admin?.id !== authState?.user?.id
        );
      })
      .filter(
        (admin: User, index: number, self: any) =>
          index === self.findIndex((a: User) => a.id === admin.id)
      );
    console.log({ users });

    try {
      // Add the comment
      await updateRequest(updatedRequest);
      await sendNotificationForStatusChange({
        id: request.id,
        name: currentUser?.name as string,
        status: newStatus,
        title: request.title,
        users:[...users?.map((node)=>node?.id),request?.agentId] as string[],
        workspaceId: request.workspaceId,
      });
      setIsModalOpen(false);
      dispatch({
        type: "UPDATE_REQUEST",
        payload: updatedRequest,
      });
    } catch (error) {
      console.error("Error updating request status:", error);
      // TODO: Add error handling UI
    }
  };

  const isAdmin =
    currentUser?.role === "SUPER_ADMIN" || currentUser?.role === "ADMIN" || currentUser?.role === "VENDOR";

  const getAdminName = (adminId: string | null) => {
    const adminListObj: { [key: string]: string } = {};

    adminState?.assosicatedAdmins?.forEach((user) => {
      if (user.role !== "AGENT") {
        if (!adminListObj[user.id]) {
          adminListObj[user.id] = user.name;
        }
      }
    });

    if (adminId) return adminListObj[adminId];
  };

  const assignAdmin = async ({
    request,
    requestId,
    adminId,
  }: {
    request: Request;
    requestId: string;
    adminId: string;
  }) => {
    const currentAdminId = requestState.requests.find(
      (req) => req.id === requestId
    )?.adminId;

    if (currentAdminId === adminId) {
      return;
    }
    if (authState.user?.role === "ADMIN" || authState.user?.role === "VENDOR") {
      const confirmChange = window.confirm(
        `Are you sure you want to reassign this request to ${getAdminName(
          adminId
        )}`
      );
      if (!confirmChange) {
        return;
      }
    }
    setUpdatingRequest(true);
    const updateFirestore = async () => {
      try {
        const requestRef = doc(db, "requests", requestId);
        await updateDoc(requestRef, {
          adminId,
          updatedAt: new Date().toISOString(),
        });

        const allNotificationPromise: any = [];
        const assignedAdmin = adminState?.assosicatedAdmins?.find(
          (user: any) => user.id === adminId
        );

        const isInitialAssign = currentAdminId === null;
        adminState?.assosicatedAdmins?.forEach((user: User) => {
          if (
            user.id === assignedAdmin?.id &&
            user.id !== authState?.user?.id
          ) {
            allNotificationPromise.push(
              insertNotification({
                title: "Request assigned",
                message: `You've been assigned a request: "${request?.title}" by "${authState?.user?.name}".`,
                userId: adminId,
                requestId: requestId,
                workspaceId: request?.workspaceId,
                key: "request",
              })
            );
          } else if (
            authState?.user?.id === assignedAdmin?.id &&
            (user.role === "SUPER_ADMIN" ||
              ((user.role === "ADMIN" || user.role === "VENDOR") &&
                ((user.officeIds.includes(request?.officeId as string) &&
                user.categoryIds.includes(request?.categoryId as string)) && isInitialAssign) ||
                user.id === currentAdminId)) &&
            user.id !== authState?.user?.id // Ensure it's not the current user
          ) {
            allNotificationPromise.push(
              insertNotification({
                title: "Request reassigned",
                message: `"${authState?.user?.name}" assigned a request: "${request?.title}" to themselves.`,
                userId: user.id,
                requestId: requestId,
                workspaceId: request?.workspaceId,
                key: "request",
              })
            );
          } else if (
            user?.id === currentAdminId &&
            (user.role === "ADMIN" || user.role === "VENDOR") &&
            ((user.officeIds.includes(request?.officeId as string) &&
              user.categoryIds.includes(request?.categoryId as string)) ||
              user.id === currentAdminId) && user.id !== assignedAdmin?.id &&
            user.id !== authState?.user?.id
          ) {
            allNotificationPromise.push(
              insertNotification({
                title: "Request reassigned",
                message: `"${authState?.user?.name}" reassigned a request: "${request?.title}" to "${assignedAdmin?.name}".`,
                userId: user.id,
                requestId: requestId,
                workspaceId: request?.workspaceId,
                key: "request",
              })
            );
          } else if (
            user.role === "SUPER_ADMIN" &&
            user.id !== authState?.user?.id
          ) {
            allNotificationPromise.push(
              insertNotification({
                title: "Request reassigned",
                message: `"${authState?.user?.name}" assigned a request: "${request?.title}" to "${assignedAdmin?.name}".`,
                userId: user.id,
                requestId: requestId,
                workspaceId: request?.workspaceId,
                key: "request",
              })
            );
          } else if (
            user?.role === "SUPER_ADMIN" &&
            authState?.user?.role === "SUPER_ADMIN" &&
            currentAdminId === null &&
            user.id !== assignedAdmin?.id &&
            user.id !== authState?.user?.id
          ) {
            allNotificationPromise.push(
              insertNotification({
                title: "Request assigned",
                message: `"${authState?.user?.name}" assigned a request: "${request?.title}" to "${assignedAdmin?.name}".`,
                userId: user.id,
                requestId: requestId,
                workspaceId: request?.workspaceId,
                key: "request",
              })
            );
          } else if (
            user?.role === "SUPER_ADMIN" &&
            authState?.user?.role === "SUPER_ADMIN" &&
            currentAdminId === null &&
            user.id !== assignedAdmin?.id &&
            user.id !== authState?.user?.id
          ) {
            allNotificationPromise.push(
              insertNotification({
                title: "Request assigned",
                message: `"${authState?.user?.name}" assigned a request: "${request?.title}" to "${assignedAdmin?.name}".`,
                userId: user.id,
                requestId: requestId,
                workspaceId: request?.workspaceId,
              })
            );
          }
        });

        await Promise.all(allNotificationPromise);
        if (authState?.user?.id !== adminId) {
          CustomToast(
            "success",
            `Request has been assigned to ${getAdminName(adminId)}`
          );
        }
        dispatch({
          type: "ASSIGN_ADMIN",
          payload: { requestId, adminId },
        });
      } catch (error) {
        console.error("Failed to update Firestore:", error);
      }
    };
    await updateFirestore();
    setUpdatingRequest(false);
  };

  const handleModalOpen = (request: Request) => {
    setSelectedRequest(request);
    setIsModalOpen(true);
  };

  const handleChangeStatus = async (request: Request, value: string) => {
    const updatedRequest: any = {
      ...request,
      status: value,
      updatedAt: new Date().toISOString(),
    };
    const users = adminState.users
      .concat(adminState?.assosicatedAdmins || [])
      .filter((admin: User) => {
        const isSuperAdmin = admin?.role === "SUPER_ADMIN";
        const isAssociatedAdmin =
          request.adminId === null &&
          admin?.categoryIds.includes(request?.categoryId as string) &&
          admin?.officeIds.includes(request?.officeId as string);
        const isAssignedAdmin = request?.adminId === admin?.id;
        const isAgent = request.agentId === admin?.id;

        return (
          (isSuperAdmin || isAssociatedAdmin || isAssignedAdmin) &&
          admin?.id !== authState?.user?.id
        );
      })
      .filter(
        (admin: User, index: number, self: any) =>
          index === self.findIndex((a: User) => a.id === admin.id)
      );

    try {
      await updateRequest(updatedRequest);
      await sendNotificationForStatusChange({
        id: request.id,
        name: currentUser?.name as string,
        status: value,
        title: request.title,
        users:[...users?.map((node)=>node?.id),request?.agentId] as string[],
        workspaceId: request.workspaceId,
      });
      dispatch({
        type: "UPDATE_REQUEST",
        payload: updatedRequest,
      });
    } catch (error) {
      console.error("Error updating request status:", error);
    }
  };

  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      loadMoreRequests();
    }
  }, [inView]);


  return (
    <div className="mx-auto max-w-7xl overflow-x-clip">
      {requestState?.loading ? (
        <div className="flex md:items-center justify-between  flex-col md:flex-row ">
          <h1 className="text-2xl font-semibold text-gray-900">All Requests</h1>
          <div className=" h-full w-full flex justify-center items-center absolute top-0 left-0 bg-white bg-opacity-20">
            <span
              className="authentication-loader
authentication-loader scale-150"
            ></span>
          </div>
        </div>
      ) : (
        <>
          <div className="flex md:items-center justify-between  flex-col md:flex-row">
            <h1 className="text-2xl font-semibold text-gray-900">
              All Requests
            </h1>
            <div className="flex items-start md:items-center gap-4 flex-col md:gap-1 lg:gap-2 md:flex-row md:space-x-2 lg:space-x-4 mt-6 md:mt-0 px-2">
              <div className="flex md:items-center space-x-2">
                <Filter className="h-5 w-5 text-gray-400" />
                <select
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                  className="rounded-md border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500 w-full"
                >
                  <option value="">All Categories</option>
                  {Array.isArray(adminState?.categories) &&
                    adminState?.categories.map((category) => (
                      <option key={category?.id} value={category?.id}>
                        {category?.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex items-center space-x-2">
                <SortDesc className="h-5 w-5 text-gray-400" />
                <select
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                  className="rounded-md border-gray-300 text-sm focus:border-blue-500 focus:ring-blue-500"
                >
                  <option value="">All Statuses</option>
                  <option value="Pending">Pending</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                </select>
              </div>
              {authState?.readOnlyUser === null && currentUser?.role === "AGENT" && (
                <Button
                  className="flex items-center space-x-2 w-fit"
                  onClick={() => {
                    navigate("/dashboard/requests/new");
                  }}
                >
                  <MessageSquare className="h-4 w-4" />
                  <span>Create New Request</span>
                </Button>
              )}
            </div>
          </div>

          <div className="mt-4">
            {sortedRequests.length === 0 ? (
              <div className="rounded-lg bg-white p-8 text-center">
                <p className="text-gray-500">
                  No requests found matching your filters.
                </p>
              </div>
            ) : (
              <>
                <div className="overflow-hidden bg-white shadow sm:rounded-md">
                  <ul role="list" className="divide-y divide-gray-200">
                    {sortedRequests.map((request, i) => {
                      const adminName = getAdminName(request.adminId);
                      const admins = (
                        adminState?.assosicatedAdmins
                          ?.filter((user) => user.role !== "AGENT")
                          .map((user) => ({
                            label: user.name,
                            value: user.id,
                          })) || []
                      ).filter((option) => option !== undefined);
                      const adminShow = getAdminName(request.adminId)
                        ? false
                        : request.adminId === null
                        ? false
                        : true;

                      
                      return (
                        <li
                          key={request.id + i}
                          id={request.id}
                          className="p-6 scroll-mt-10"
                        >
                          <div className="flex sm:flex-row flex-col-reverse sm:items-center items-start justify-between mb-4 sm:gap-0 gap-4">
                            <div>
                              {request?.title && (
                                <p
                                  className="border-none p-0 bg-transparen text-lg font-medium text-blue-600 focus:outline-none focus:shadow-none
                              focus:border-none"
                                >
                                  {request.title}
                                </p>
                              )}

                              {request.originalAnalysis && (
                                <p className="text-sm text-gray-500">
                                  Originally suggested:{" "}
                                  {request.originalAnalysis.title}
                                </p>
                              )}
                            </div>
                            {isAdmin ? (
                              <Space
                                style={{ width: "100%", maxWidth: "150px" }}
                                direction="vertical"
                              >
                                <Select
                                  className={`w-full mt-1 [&>div]:!p-0 [&>div>span>span:last-child]:!pl-[11px]
                         ${
                           request?.status === "Completed"
                             ? "[&>div>span>span:last-child]:text-green-700 [&>div>span>span:last-child]:font-semibold [&>div]:!bg-green-100"
                             : request?.status === "Pending"
                             ? "[&>div>span>span:last-child]:text-yellow-800 [&>div>span>span:last-child]:font-semibold [&>div]:!bg-yellow-100"
                             : request?.status === "In Progress"
                             ? "[&>div>span>span:last-child]:text-blue-800 [&>div>span>span:last-child]:font-semibold [&>div]:!bg-blue-100"
                             : "text-blue-800"
                         }
                         ${
                           request?.adminId === null &&
                           (authState?.user?.role === "ADMIN" || authState?.readOnlyUser?.role === "ADMIN" || authState?.user?.role === "VENDOR")
                             ? "[&>div]:opacity-60"
                             : ""
                         }
                          `}
                          disabled={
                            (authState?.readOnlyUser !== null
                               || request?.adminId === null &&
                              (authState?.user?.role === "ADMIN" || authState?.user?.role === "VENDOR")) ||
                              request?.isAireponseUpdated === false
                          }
                          placeholder="Please select role"
                          value={request?.status}
                          onChange={(value) =>
                            handleChangeStatus(request, value)
                          }
                          options={[
                            { value: "Pending", label: "Pending" },
                            {
                              value: "In Progress",
                              label: "In Progress",
                            },
                            { value: "Completed", label: "Completed" },
                          ]}
                          />
                      </Space>
                            ) : (
                              <span
                                className={cn(
                                  "sm:ml-2 inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium",
                                  {
                                    "bg-blue-100 text-blue-800":
                                      request.status === "In Progress",
                                    "bg-yellow-100 text-yellow-800":
                                      request.status === "Pending",
                                    "bg-green-100 text-green-800":
                                      request.status === "Completed",
                                  }
                                )}
                              >
                                {request.status}
                              </span>
                            )}
                          </div>

                          <div className="flex items-center space-x-2 flex-wrap text-sm text-gray-500 mb-4">
                            <span>
                              {getCategoryNameById(request?.categoryId) ||
                                request?.category}
                            </span>
                            {request.originalAnalysis && (
                              <>
                                <span>
                                  • Originally:{" "}
                                  {request.originalAnalysis.category}
                                </span>
                              </>
                            )}
                            <span>
                              • {new Date(request.createdAt).toLocaleString()}
                            </span>
                          </div>

                          <div className="flex justify-between items-start md:items-center mb-4 flex-col gap-4 md:flex-row">
                            <div className="flex items-center gap-1 sm:gap-4 flex-wrap sm:flex-nowrap text-sm text-gray-500  ">
                              <label
                                htmlFor="role"
                                className="block text-sm font-medium text-gray-700 whitespace-nowrap"
                              >
                                Assignee :
                              </label>
                              {isAdmin ? (
                                <Space
                                  style={{ width: "100%", maxWidth: "150px" }}
                                  direction="vertical"
                                >
                                  <Select
                                    disabled={
                                      authState?.readOnlyUser !== null ||
                                      request.category.trim().toLowerCase() === 'ai assistant' ||
                                      updatingRequest ||
                                      (isAdmin &&
                                        request.status === "Completed") || request?.isAireponseUpdated === false
                                    }
                                    loading={adminShow}
                                    className="w-full min-w-28 mt-1 placeholder:text-gray-400"
                                    placeholder="Unassigned"
                                    value={adminName}
                                    onChange={(value) =>
                                      assignAdmin({
                                        request: request,
                                        requestId: request?.id,
                                        adminId: value,
                                      })
                                    }
                                    options={admins}
                                  />
                                </Space>
                              ) : (
                                <label htmlFor="">
                                  {getAdminName(request?.adminId) ||
                                    "Not assigned"}
                                </label>
                              )}
                            </div>
                            {request?.createdBy && (
                              <div className="flex items-center space-x-2 flex-wrap text-sm text-gray-500 ">
                                <label
                                  htmlFor="role"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Created By:
                                  <span className="w-full font-normal ml-2 text-gray-500">
                                    {request?.createdBy || ""}
                                  </span>
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="space-y-4">
                            {request.transcript && (
                              <div className="rounded-lg border border-gray-200 bg-gray-50 p-4">
                                <h3 className="text-sm font-medium text-gray-700 mb-2">
                                  Transcript
                                </h3>
                                <p className="text-sm text-gray-600">
                                  {request.transcript}
                                </p>
                                {/* {request.whisperData && (
                                  <p className="mt-2 text-xs text-gray-500">
                                    Confidence:{" "}
                                    {Math.round(
                                      request.whisperData.confidence * 100
                                    )}
                                    %
                                  </p>
                                )} */}
                              </div>
                            )}

                            {request.audioUrl && (
                              <div>
                                <h3 className="text-sm font-medium text-gray-700 mb-2">
                                  Audio Recording
                                </h3>
                                <audio
                                  className="w-full"
                                  controls
                                  src={request.audioUrl}
                                />
                              </div>
                            )}

                            {request?.isAireponseUpdated  === undefined || request?.isAireponseUpdated  === null ||   request?.isAireponseUpdated ? (
                              <RequestComments
                                requestId={request.id}
                                comments={request.comments || []}
                                originalAnalysis={request?.originalAnalysis}
                                request={request}
                              />
                            ) : (
                              <div className="h-full w-full flex justify-start gap-3 items-center">
                                <span className="authentication-loader-small"></span>
                                <span className="text-sm">
                                  Please wait while the AI processes the
                                  request.
                                </span>
                              </div>
                            )}
                          </div>

                          {request.status !== "Completed" &&
                            ((authState.user?.role === "ADMIN" || authState.user?.role === "VENDOR") ||
                              authState.user?.role === "SUPER_ADMIN") &&
                            canProcessRequest(authState.user, request) && (
                              <div className="mt-4 flex justify-end">
                                <Button
                                  disabled={
                                    request?.isAireponseUpdated !== true ||
                                    authState?.readOnlyUser !== null ||
                                    (authState.user?.role === "ADMIN" || authState.user?.role === "VENDOR") &&
                                    request?.adminId === null
                                  }
                                  variant="outline"
                                  onClick={() =>
                                    request.status === "In Progress"
                                      ? handleModalOpen(request)
                                      : handleStatusUpdate(request)
                                  }
                                  className="text-gray-500"
                                >
                                  {request.status === "Pending"
                                    ? "Start Processing"
                                    : "Mark Complete"}
                                </Button>
                              </div>
                            )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {(requestState.hasMore && !requestState?.loadedSuggestion) && (
                  <div ref={ref}>
                    <LoaderSpinner otherClasses="h-56" />
                  </div>
                )}
                {isModalOpen && (
                  <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    title={""}
                  >
                    <p>
                      Are you sure you want to mark this request as completed?
                    </p>
                    <div className="flex items-center justify-end gap-3 mt-4">
                      <Button onClick={() => setIsModalOpen(false)}>
                        Cancel
                      </Button>
                      <Button
                        onClick={() => {
                          handleStatusUpdate(
                            selectedRequest || ({} as Request)
                          );
                        }}
                      >
                        Confirm
                      </Button>
                    </div>
                  </Modal>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
